import * as React from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Heading,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useInstallations } from 'models/github/queries'
import { AuthRequiredError } from 'api/utils'
import Page from 'view/Page'
import Link from 'view/components/Link'

const Contents = () => {
  const { installationId } = useParams<{ installationId: string }>()
  const { isLoading, error, data } = useInstallations()
  const history = useHistory()
  const location = useLocation()

  if (isLoading) {
    return (
      <Box>
        <Text fontSize="md" color="gray.600">
          <Spinner size="sm" mr={4} />
          Loading Installations
        </Text>
      </Box>
    )
  }

  if (error || !data) {
    // TODO: how to do this in a generic, clean way?
    // TODO: decouple this from the api
    if (error && error instanceof AuthRequiredError) {
      history.push(
        `/login?auth_expired=true&return_location=${location.pathname}`
      )
    }

    return (
      <>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Failed to Access Installations</AlertTitle>
          <AlertDescription>We failed to access your account</AlertDescription>
        </Alert>
        <Text fontSize="md" color="gray.600">
          Something went sideways while we were trying to figure out which
          repositories you have access to. Please try again and let us know if
          you're stuck.
        </Text>
      </>
    )
  }

  return (
    <>
      <Text>Installation: {installationId}</Text>
      <Box mt={4}>
        <Link to={`reviews`}>View Reviews Page</Link>
      </Box>
    </>
  )
}

const TestPage = () => {
  return (
    <Page>
      <Box>
        <Box
          backgroundColor="white"
          borderRadius="lg"
          shadow="sm"
          px={3}
          py={5}
          m={12}
        >
          <Flex
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-around"
          >
            <Heading
              size="md"
              as="h2"
              lineHeight="shorter"
              fontWeight="bold"
              fontFamily="heading"
              textAlign="center"
            >
              Dashboard
            </Heading>
          </Flex>
          <Stack shouldWrapChildren spacing={4} ml={4} mt={4}>
            <Stack shouldWrapChildren spacing={2}>
              <Box textAlign="center">
                <Contents />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Page>
  )
}

export default TestPage
