import * as React from 'react'

export type LogoOrientation = 'horizontal' | 'vertical'

interface SVGProps {
  controlId?: string
  title?: string
  color1?: string
  color2?: string
}

interface LogoProps {
  orientation?: LogoOrientation
}

const HorizontalLogo = (props: SVGProps) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 173 27"
    role="img"
    aria-labelledby={props.controlId}
  >
    <title id={props.controlId}>{props.title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.511 8.442V6.237c0-3.859 0-5.237-6.615-5.237-6.616 0-6.616 1.378-6.616 5.237v9.923c0 3.86 0 5.238 6.616 5.238 6.615 0 6.615-1.379 6.615-5.238v-6.064h-6.615v3.308H68.1v2.756c0 1.379 0 1.93-2.205 1.93s-2.205-.551-2.205-1.93V6.237c0-1.378 0-1.93 2.205-1.93s2.205.552 2.205 1.93v2.205h4.41zM24.257 4.583V1.276h-9.648l-1.93 16.538h-1.102L9.647 1.276H0v3.307h1.103v13.231H0v3.308h5.513V4.583h1.102l1.93 16.539h7.167l1.93-16.539h1.102v16.539h5.512v-3.308h-1.102V4.584h1.102zM39.69 6.788V1.276h-13.23v3.307h1.102v13.231h-1.102v3.308h13.23v-5.513h-4.41v2.205h-3.307v-4.961h6.064V9.545h-6.064V4.583h3.307v2.205h4.41zm14.535 7.498l3.461 8.528H58v-.004h1V22l3 2.5-3 2.5v-.88l-1.313.002h1.103-4.41l-4.288-10.485-2.398.799v4.686H42.18v-3.308h1.102V4.584h-1.102V1.275h7.718c6.615 0 6.615 1.102 6.615 4.961v7.305l-2.288.744zm-6.532-1.35l4.41-1.462V6.237c0-1.102 0-1.654-2.205-1.654h-2.205v8.352zm40.531-11.66v5.512h-4.41V4.583h-3.308v4.962h6.064v3.308h-6.064v4.961h3.308V15.61h4.41v5.513h-13.23v-3.308h1.102V4.584h-1.103V1.275h13.231z"
      fill={props.color1 ? props.color1 : '#5849BF'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.154 3.006L166.16 0v1.206l2.157-.004c2.745 0 4.339 1.56 4.339 4.384V16.46h-.001c-.026 3.296-.427 4.265-6.621 4.265h-8.789v-3.321h1.104V4.118h-1.104V.85h5.515v3.268h.004v13.286h3.27c1.732 0 2.105-.34 2.185-1.022l.001-11.589h-2.06v1.22l-3.006-3.007zm-58.086 2.802v2.205h-4.41V5.808c0-1.379 0-1.93-2.205-1.93s-2.205.551-2.205 1.93v2.48l8.82 2.895v4.548c0 3.859 0 5.237-6.615 5.237-6.616 0-6.616-1.378-6.616-5.237v-2.206h4.41v2.206c0 1.378 0 1.93 2.206 1.93 2.205 0 2.205-.552 2.205-1.93V13.25l-8.82-2.894V5.808c0-3.86 0-5.238 6.615-5.238s6.615 1.379 6.615 5.238zm2.759 0c0-3.86 0-5.238 6.615-5.238 6.616 0 6.616 1.379 6.616 5.238v9.923c0 3.362 0 4.851-4.411 5.182V24h-4.41v-3.087c-4.41-.331-4.41-1.82-4.41-5.182V5.808zm6.615 11.852c2.205 0 2.205-.551 2.205-1.93V5.809c0-1.379 0-1.93-2.205-1.93s-2.205.551-2.205 1.93v9.923c0 1.378 0 1.93 2.205 1.93zM133.05.846v14.885c0 1.378 0 1.93-2.205 1.93s-2.205-.552-2.205-1.93V.846h-5.513v3.308h1.103V15.73c0 3.859 0 5.237 6.615 5.237 6.616 0 6.616-1.378 6.616-5.237V4.154h1.102V.846h-5.513zm18.898 0l2.756 16.538h1.103v3.308h-4.962l-.551-3.308h-5.513l-.551 3.308h-4.962v-3.308h1.103L143.127.846h8.821zm-4.962 3.308l-1.653 9.923h4.41l-1.654-9.923h-1.103z"
      fill={props.color2 ? props.color2 : '#081413'}
    />
  </svg>
)

const VerticalLogo = (props: SVGProps) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90 47"
    role="img"
    aria-labelledby={props.controlId}
  >
    <title id={props.controlId}>{props.title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.5112 8.44235V6.23721C73.5112 2.37821 73.5112 1 66.8958 1C60.2804 1 60.2804 2.37821 60.2804 6.23721V16.1603C60.2804 20.0193 60.2804 21.3976 66.8958 21.3976C73.5112 21.3976 73.5112 20.0193 73.5112 16.1603V10.0962H66.8958V13.4039H69.101V16.1603C69.101 17.5386 69.101 18.0898 66.8958 18.0898C64.6907 18.0898 64.6907 17.5386 64.6907 16.1603V6.23721C64.6907 4.859 64.6907 4.30771 66.8958 4.30771C69.101 4.30771 69.101 4.859 69.101 6.23721V8.44235H73.5112ZM25.2565 4.58335V1.27564H15.6091L13.6796 17.8142H12.577L10.6475 1.27564H1V4.58335H2.10257V17.8142H1V21.1219H6.51285V4.58335H7.61542L9.54492 21.1219H16.7116L18.6411 4.58335H19.7437V21.1219H25.2565V17.8142H24.154V4.58335H25.2565ZM40.6915 6.78849V1.27564H27.4606V4.58335H28.5632V17.8142H27.4606V21.1219H40.6915V15.6091H36.2812V17.8142H32.9735V12.8526H39.0376V9.54492H32.9735V4.58335H36.2812V6.78849H40.6915ZM55.2264 14.286L58.6873 22.8142H59V22.81H60V22L63 24.5L60 27V26.12L58.6873 26.1219H59.7899H55.3796L51.0918 15.6366L48.6937 16.436V21.1219H43.1809V17.8142H44.2834V4.58335H43.1809V1.27564H50.8989C57.5143 1.27564 57.5143 2.37821 57.5143 6.23721V13.5417L55.2264 14.286ZM48.6937 12.9353L53.104 11.4744V6.23721C53.104 5.13464 53.104 4.58335 50.8989 4.58335H48.6937V12.9353ZM89.225 1.27564V6.78849H84.8147V4.58335H81.507V9.54492H87.5712V12.8526H81.507V17.8142H84.8147V15.6091H89.225V21.1219H75.9942V17.8142H77.0967V4.58335H75.9942V1.27564H89.225Z"
      fill={props.color1 ? props.color1 : '#5849BF'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.7488 22.1L79.7626 25.1061L82.7488 28.1123V26.8933H84.8019L84.8014 38.4818C84.7215 39.1631 84.3508 39.5038 82.6305 39.5038H79.382V26.2184H79.3781V22.95H73.9V26.2184H74.9964V39.5038H73.9V42.8252H82.6305C88.7838 42.8252 89.1814 41.8565 89.2071 38.5601H89.2089V27.6864C89.2089 24.8611 87.6252 23.3023 84.8978 23.3023L82.7488 23.3063V22.1ZM14.1431 28.2372V30.4424H9.76207V28.2372C9.76207 26.859 9.76207 26.3077 7.57155 26.3077C5.38103 26.3077 5.38104 26.859 5.38104 28.2372V30.718L14.1431 33.6122V38.1603C14.1431 42.0193 14.1431 43.3976 7.57155 43.3976C1 43.3976 1 42.0193 1 38.1603V35.9552H5.38104V38.1603C5.38104 39.5386 5.38103 40.0898 7.57155 40.0898C9.76207 40.0898 9.76207 39.5386 9.76207 38.1603V35.6796L1 32.7853V28.2372C1 24.3782 1 23 7.57155 23C14.1431 23 14.1431 24.3782 14.1431 28.2372ZM24.8905 23C18.319 23 18.319 24.3782 18.319 28.2372V38.1603C18.319 41.5232 18.319 43.0117 22.7 43.3424V46.4296H27.081V43.3424C31.4621 43.0117 31.4621 41.5232 31.4621 38.1603V28.2372C31.4621 24.3782 31.4621 23 24.8905 23ZM27.081 38.1603C27.081 39.5386 27.081 40.0898 24.8905 40.0898C22.7 40.0898 22.7 39.5386 22.7 38.1603V28.2372C22.7 26.859 22.7 26.3077 24.8905 26.3077C27.081 26.3077 27.081 26.859 27.081 28.2372V38.1603ZM45.1763 23V37.8847C45.1763 39.2629 45.1763 39.8142 42.9858 39.8142C40.7953 39.8142 40.7953 39.2629 40.7953 37.8847V23H35.319V26.3077H36.4142V37.8847C36.4142 41.7437 36.4142 43.1219 42.9858 43.1219C49.5573 43.1219 49.5573 41.7437 49.5573 37.8847V26.3077H50.6526V23H45.1763ZM56.7338 27.6219L54.5953 39.5386H53.5V42.8463H58.4287L58.9763 39.5386H64.4526L65.0002 42.8463H69.9289V39.5386H68.8336L66.0955 23H62.9811L64.7811 24.5L62.2621 27L63.905 36.2308H59.5239L61.0956 27.7379L58.881 29.7756V27.6219H56.7338Z"
      fill={props.color2 ? props.color2 : '#081413'}
    />
  </svg>
)

const Logo = (props: LogoProps & SVGProps) => (
  <>
    {props.orientation === 'vertical' ? (
      <VerticalLogo {...props} />
    ) : (
      <HorizontalLogo {...props} />
    )}
  </>
)

export default Logo
