export function randomString(length?: number) {
  const chars = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789')
  return [...Array(length || 10)]
    .map((i) => chars[(Math.random() * chars.length) | 0])
    .join('')
}

export function queryStringAdd(
  locationSearch: string,
  key: string,
  value: string
) {
  // break into object
  const params = new URLSearchParams(locationSearch)

  // add key: value if not already there
  params.delete(key)
  params.append(key, value)

  // put back together
  return params.toString()
}
