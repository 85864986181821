import theme from 'view/theme'

export const zIndexes = {
  nav: 1100,
  popover: 1200,
}

export const STATUS_COLORS = {
  open: theme.colors.green['400'],
  approved: theme.colors.purple['200'],
  waiting: theme.colors.yellow['300'],
  inreview: theme.colors.blue['500'],
  closed: theme.colors.red['400'],
  merged: theme.colors.purple['400'],
}

export const STATUS_NAMES: string[] = [
  'Closed',
  'Open',
  'Waiting',
  'In Review',
  'Approved',
  'Merged',
]

export const DEFAULT_TIME_FORMAT = 'MMM D, YYYY h:mm A'
