import * as React from 'react'
import { ResponsiveBar } from '@nivo/bar'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export type BarChartData = {
  key: string
  [k: string]: number | string
}

interface Props {
  data: BarChartData[]
  yAxisLabel: string
  xAxisLabel: string
  keys: string[]
  hideLabels?: boolean
  colorMap?: { [key: string]: string }
}

const DivergingBarChart = (props: Props) => (
  <ResponsiveBar
    data={props.data}
    keys={props.keys}
    indexBy="key"
    margin={{ top: 50, right: 130, bottom: 85, left: 60 }}
    padding={0.3}
    //    colors={{ scheme: 'red_yellow_green' }}
    colors={({ id, data }) => {
      if (data[`${id}Color`]) {
        return data[`${id}Color`]
      }

      if (props.colorMap && props.colorMap[id]) {
        return props.colorMap[id]
      }

      // default color?
      return '#e0e0e0'
    }}
    enableGridY={true}
    enableGridX={false}
    // defs={[
    //     {
    //         id: 'dots',
    //         type: 'patternDots',
    //         background: 'inherit',
    //         color: '#38bcb2',
    //         size: 4,
    //         padding: 1,
    //         stagger: true
    //     },
    //     {
    //         id: 'lines',
    //         type: 'patternLines',
    //         background: 'inherit',
    //         color: '#eed312',
    //         rotation: -45,
    //         lineWidth: 6,
    //         spacing: 10
    //     }
    // ]}
    // fill={[
    //     {
    //         match: {
    //             id: 'fries'
    //         },
    //         id: 'dots'
    //     },
    //     {
    //         match: {
    //             id: 'sandwich'
    //         },
    //         id: 'lines'
    //     }
    // ]}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    // axisRight={null}

    axisTop={
      props.hideLabels
        ? null
        : {
            tickSize: 0,
            tickPadding: 12,
          }
    }
    axisBottom={
      props.hideLabels
        ? null
        : {
            legend: props.xAxisLabel,
            legendPosition: 'middle',
            legendOffset: 50,
            tickSize: 0,
            tickPadding: 12,
          }
    }
    axisLeft={null}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
)

export default DivergingBarChart
