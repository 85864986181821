import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import Page from './Page'

import { FaChartLine, FaThumbsUp, FaUserSlash } from 'react-icons/fa'
import SideBySideSection, {
  FlippedOnMobile,
  TextSideBox,
  ImageSideBox,
} from './components/Landing/SideBySideSection'
import PricingSection from './components/Landing/PricingSection'
import FeatureBox from './components/Landing/FeatureBox'
import heroImage from './images/undraw_super_woman_dv0y.svg'
import reportImage from './images/undraw_report_mx0a.svg'
import landingShape1 from './images/landing-1-shape.svg'

const heroConfig = {
  title: `Dead Simple Repo Analytics for Team Leads`,
  details: `Get smart, actionable performance metrics using the
  repositories and commits you already love.`,
  image: heroImage,
  imageAlt: 'superhero',
}

interface FeatureConfig {
  icon: React.ReactNode
  title: string
  details: string
}

const createIcon = (icon: typeof FaChartLine) => (
  <Box padding="4" borderRadius={32} bg="purple.50" w="16" h="16">
    <Box as={icon} color="brand" w="8" h="8" />
  </Box>
)

const FEATURES: Array<FeatureConfig> = [
  {
    icon: createIcon(FaChartLine),
    title: 'Measure What Matters',
    details: `Easy access to the stats you'll actually use, including: time-to-review,
    time-to-merge, overloaded reviewers, stale branches, and more.`,
  },
  {
    icon: createIcon(FaThumbsUp),
    title: 'Easy Setup',
    details: `Connect your repositories and get insights immediately.
    Connect your chat apps and get updates delivered automatically.`,
  },
  {
    icon: createIcon(FaUserSlash),
    title: 'No Team Overhead',
    details: `Keep your team focused - get all the awesome without
    adding any new processes or workflow requirements for your developers.`,
  },
]

const heroDetails = {
  title: 'Steroids for Eng Leaders',
  details: `Get smart, actionable performance metrics using the repositories
  and commits you already love.`,
  buttonText: 'Connect Your Repository',
  buttonHref: '/login',
}

const collaborateDetails = {
  title: 'By Managers, For Managers',
  details: `
    We built Merge Squad to automate the collection and analysis of repo metrics
    we were already relying upon each week to lead our teams more effectively. After feedback from peers at
    Series A and B-stage companies, it was clear that while countless others crave
    these insights, they simply don't have the resources to invest in a new reporting
    process, custom dashboard configurations, or yet another hosted project.
  `,
  image: reportImage,
  imageAlt: 'man looking at graphs on a report',
  callout: 'Find out what YOUR repository is dying to tell you.',
  cta: 'Connect a Repo',
  linkHref: '/#pricing',
}
export const sectionPadding = [8, null, 16]

// rendered twice so we can do two different layouts because chakra
// grid doesn't support grid direction (on mobile want the image first
// but on desktop want the image second)
const renderHeroTextBox = () => (
  <Box>
    <Heading>{heroDetails.title}</Heading>
    <Text mt={4}>{heroDetails.details}</Text>

    <Box my={8} style={{ borderBottom: '1px solid lightgray' }} />
    <Text
      my={8}
      textAlign={['center', null, 'left']}
      fontSize="md"
      lineHeight="2"
    >
      What is Your Repository Dying to Tell You?
    </Text>
    <Box textAlign={['center', null, 'left']}>
      <Link to={heroDetails.buttonHref}>
        <Button
          size="md"
          variant="solid"
          colorScheme="purple"
          fontWeight="normal"
          mb={4}
        >
          {heroDetails.buttonText}
        </Button>
      </Link>
    </Box>
  </Box>
)

const renderHeroImageBox = () => (
  <Box>
    <Image w="400px" h="400px" src={heroConfig.image} alt="superhero" />
  </Box>
)

const LandingPage = () => {
  return (
    <Page>
      <Flex
        width="full"
        height="100%"
        align="center"
        justifyContent="center"
        flexDirection="column"
        padding={0}
        bg="white"
      >
        <FlippedOnMobile
          sectionPadding={sectionPadding}
          left={renderHeroTextBox()}
          right={renderHeroImageBox()}
        />

        <Box>
          <Image width="100%" src={landingShape1} mb="-20px" />
          <Box background="#eef4f5" padding={sectionPadding} pb={16}>
            <Heading mb={16} fontWeight="thin" textAlign="center">
              Features You Need
            </Heading>
            <SimpleGrid columns={[1, null, 3]} spacing={6}>
              {FEATURES.map((feature) => (
                <FeatureBox {...feature} />
              ))}
            </SimpleGrid>
          </Box>
        </Box>

        <SideBySideSection
          sectionPadding={sectionPadding}
          left={<ImageSideBox {...collaborateDetails} />}
          right={<TextSideBox {...collaborateDetails} />}
        />

        <PricingSection />
      </Flex>
    </Page>
  )
}

export default LandingPage
