import * as React from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Heading, Text } from '@chakra-ui/react'

// export const pricingBetaDetails = {
//   title: 'Hello Early Adopters!',
//   details: `
//     We're slowly expanding our analytics to more teams to make sure everything
//     works well for different workflows. If you want to help us out with this
//     (and probably suffer some bugs along the way) we're giving our active beta
//     users LIFETIME DISCOUNTS. Email us at beta@mergesquad.com and we'll get you
//     started.
//   `,
//   linkHref: 'mailto:beta@mergesquad.com',
//   buttonText: 'Email Us for Early Access',
//   additionalText: `
//     If you would rather wait for prime time we are giving discounts to our
//     first batch of signups after launch as well. Email us at
//     waitinglist@mergesquad.com and we'll let you know when the big day comes.
//     `,
// }

export const pricingDetails = {
  title: 'Free + Pro Features',
  details: (
    <>
      <p>
        We think MergeSquad makes every repo collaboration a little bit better
        so we want it to be available to everyone! Get started now for free!
      </p>
    </>
  ),
  linkHref: '/login',
  buttonText: 'Login/Signup Now',
  additionalText: (
    <>
      <p>
        If you are managing a team you might want our pro account which includes
        first access to new features, deeper stats, support, and our robot team
        butler that points out red flags for you automatically so you can focus
        on the rest of your job and just get alerted when you might need to look
        into something.
      </p>
      <br />
      <p>
        As we iterate we're looking for the perfect first customers so if you
        care a lot about your dev team health and want to help us make your
        dream product by using MergeSquad at work and giving lots of feedback
        then email us at awesome@mergesquad.com!
      </p>
      {/*
      <p>
        Pricing is per month and either per seat (with unlimited repos) or per
        repository (with unlimited seats) -- we know dev teams come in all
        shapes and sizes so pick whatever works best for you.
      </p>
      */}
    </>
  ),
  features: [
    'Team and Code Metrics',
    'Unlimited Chat Notifications',
    'Scheduled Metrics Delivery',
    'Team Trends Over Time',
    'Influence our Roadmap',
  ],
  plans: [
    {
      title: 'Open Source',
      currency: '$',
      perSeatPrice: 'FREE',
      perRepoPrice: 'FREE',
      details:
        'Open source makes the world go round. Free for public repositories.',
      includedFeatures: [
        'Team and Code Metrics',
        'Unlimited Chat Notifications',
        'Scheduled Metrics Delivery',
        'Team Trends Over Time',
      ],
    },
    {
      title: 'Contractors',
      currency: '$',
      perSeatPrice: '2.99',
      perRepoPrice: '9.99',
      details: `A slimmed down version for smaller teams.`,
      includedFeatures: [
        'Team and Code Metrics',
        'Unlimited Chat Notifications',
      ],
    },
    {
      popular: true,
      title: 'Professionals',
      currency: '$',
      perSeatPrice: '4.99',
      perRepoPrice: '19.99',
      details: `Everything you need to run a medium sized team and up`,
      includedFeatures: [
        'Team and Code Metrics',
        'Unlimited Chat Notifications',
        'Scheduled Metrics Delivery',
        'Team Trends Over Time',
        'Influence our Roadmap',
      ],
    },
  ],
}

const PricingSection: React.FC = () => (
  <Box bg="#30393b" width="100%">
    <Box maxWidth="700px" m="auto" padding={8} textAlign="justify">
      <Heading color="white" mb={4} textAlign="center">
        {pricingDetails.title}
      </Heading>
      <Text color="gray.400" mb={4} lineHeight="2">
        {pricingDetails.details}
      </Text>
      <Box textAlign="center">
        <Link to={pricingDetails.linkHref}>
          <Button
            size="md"
            variant="solid"
            colorScheme="purple"
            fontWeight="normal"
            mb={4}
          >
            {pricingDetails.buttonText}
          </Button>
        </Link>
      </Box>
      <Text color="gray.400" fontSize="xs" lineHeight="2">
        {pricingDetails.additionalText}
      </Text>
    </Box>
  </Box>
)

export default PricingSection
