import {
  RequestError,
  get as getWrapper,
  post as postWrapper,
} from './fetchWrapper'
import { ENDPOINT } from './types'
import { API_BASE_URL } from 'config'

const AUTH_TOKEN_STORAGE_KEY = 'mergesquad_auth_token'

export class AuthRequiredError extends Error {}

export function getAuthToken(): string | null {
  if (window.localStorage) {
    return window.localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)
  }
  return null
}

export function setAuthToken(token: string | null) {
  if (token) {
    window.localStorage &&
      window.localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token)
  } else {
    window.localStorage &&
      window.localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY)
  }
}

export async function post(endpoint: ENDPOINT, data = {}, includeAuth = true) {
  const headers: { [k: string]: string } = {}
  const authToken = getAuthToken()
  if (authToken) {
    headers['Authorization'] = `Token ${authToken}`
  }

  // if 401, clear out token
  try {
    return await postWrapper(`${API_BASE_URL}/${endpoint}`, data, headers)
  } catch (err) {
    if (err instanceof RequestError) {
      if (err.statusCode === 401 || err.statusCode === 403) {
        setAuthToken(null)
        throw new AuthRequiredError()
      }
    }
  }
}

export async function get(
  endpoint: ENDPOINT,
  data?: { [k: string]: string | number }
) {
  const headers: { [k: string]: string } = {}
  const authToken = getAuthToken()
  if (authToken) {
    headers['Authorization'] = `Token ${authToken}`
  }

  let url = `${API_BASE_URL}/${endpoint}`
  if (data) {
    const params = new URLSearchParams()
    Object.keys(data).forEach((key: string) => {
      params.set(`${key}`, `${data[key]}`)
    })
    url += `?${params.toString()}`
  }

  // if 401, clear out token
  try {
    return await getWrapper(url, headers)
  } catch (err) {
    if (err instanceof RequestError) {
      if (err.statusCode === 401 || err.statusCode === 403) {
        setAuthToken(null)
        throw new AuthRequiredError()
      }
    }
  }
}
