import * as React from 'react'
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react'
import {
  Link as ReactRouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

// https://github.com/chakra-ui/chakra-ui/issues/148#issuecomment-540457308
type LinkProps = ChakraLinkProps & RouterLinkProps

const Link = (props: LinkProps) => (
  <ChakraLink {...props}>
    <ReactRouterLink {...props}>{props.children}</ReactRouterLink>
  </ChakraLink>
)

export default Link
