import * as React from 'react'
import { PRLifecycle } from 'api/github'
import { hoursAndDaysFormatter } from 'view/utils'
import LineChart, { LineChartData } from 'view/components/LineChart'
import ChartPageCard, { WidgetSize } from 'view/components/ChartPageCard'

interface Props {
  size?: WidgetSize
  maxMRCount: number
  lifecycles?: PRLifecycle
}

const getContents = (
  lifecycles: Exclude<Props['lifecycles'], undefined>,
  maxMRCount: number
) => {
  let milestoneData: LineChartData[] = []

  const prLabels = Object.keys(lifecycles).reverse()
  for (let i = 0, n = prLabels.length; i < n; i++) {
    const prLabel = prLabels[i]
    const prMilestones = lifecycles[prLabel]
    const firstReview =
      prMilestones.first_review !== null ? prMilestones.first_review / 86400 : 0
    const approvalFromFirstReview =
      prMilestones.approval_from_first_review !== null
        ? prMilestones.approval_from_first_review / 86400
        : 0
    const mergedFromApproval =
      prMilestones.merged_from_approval !== null
        ? prMilestones.merged_from_approval / 86400
        : 0

    // only show PRs that have at least one review
    if (firstReview) {
      milestoneData.push({
        id: prLabel,
        data: [
          { x: 'First Review', y: firstReview },
          { x: 'First Review to Approved', y: approvalFromFirstReview },
          { x: 'Approved to Merged', y: mergedFromApproval },
        ],
      })
    }

    if (milestoneData.length === maxMRCount) {
      break
    }
  }

  return milestoneData && milestoneData.length !== 0 ? (
    <LineChart
      data={milestoneData}
      yLabel="Time Spent"
      xLabel="Milestone"
      yFormat={(datum) => hoursAndDaysFormatter(datum, 'No Data')}
    />
  ) : null
}

const RequestedForReview = (props: Props) => {
  return (
    <ChartPageCard
      size={props.size || 'l'}
      title={`PR Milestones (Last ${props.maxMRCount} PRs)`}
      emptyTitle="No PRs with a Review!"
    >
      {props.lifecycles
        ? getContents(props.lifecycles, props.maxMRCount)
        : null}
    </ChartPageCard>
  )
}

export default RequestedForReview
