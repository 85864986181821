import * as React from 'react'
import {
  // IconButton,
  // Popover,
  // PopoverBody,
  // PopoverCloseButton,
  // PopoverContent,
  // PopoverHeader,
  // PopoverArrow,
  // PopoverTrigger,
  PopoverProps,
} from '@chakra-ui/react'
// import { FaQuestionCircle } from 'react-icons/fa'
// import { zIndexes } from 'view/constants'

interface Props {
  id: string
  title: React.ReactNode
  contents: React.ReactNode
  fontSize?: string
  placement?: PopoverProps['placement']
}

// TODO: rendering popover locks up the tab and spends >100% cpu
const HelpIcon = (props: Props) => (
  <></>
  // <Popover
  //   trigger="click"
  //   id={`${props.id}-popover`}
  //   placement={props.placement}
  // >
  //   <PopoverTrigger>
  //     <IconButton
  //       id={`${props.id}-trigger`}
  //       // icon={<Icon name="FaQuestionCircle"/>}
  //       icon={<FaQuestionCircle/>}
  //       color="black"
  //       aria-label="click for info"
  //       variant="link"
  //     />
  //   </PopoverTrigger>
  //   <PopoverContent
  //     zIndex={zIndexes.popover}
  //     fontSize={props.fontSize ? props.fontSize : 'xs'}
  //   >
  //     <PopoverHeader fontWeight="semibold">{props.title}</PopoverHeader>
  //     <PopoverArrow />
  //     <PopoverCloseButton />
  //     <PopoverBody>{props.contents}</PopoverBody>
  //   </PopoverContent>
  // </Popover>
)

export default HelpIcon
