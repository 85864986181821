// TODO: expose way more chart options as props
import * as React from 'react'
import { DataFormatter } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export type LineChartData = {
  id: string
  color?: string
  data: Array<{
    x: string | number
    y: string | number
  }>
}

interface Props {
  yLabel: string
  xLabel: string
  data: LineChartData[]
  yFormat?: DataFormatter
}

const LineChart = (props: Props) => {
  return (
    <ResponsiveLine
      data={props.data}
      margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
      lineWidth={2}
      enableGridX={true}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat={props.yFormat ? props.yFormat : ' >-.2f'}
      isInteractive={true}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        // orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: props.xLabel,
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        // orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: props.yLabel,
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      // useMesh={true}
      curve="linear"
      // enableCrosshair={true}
      animate={true}
      enableSlices="x"
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  )
}

export default LineChart
