import * as React from 'react'
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
} from '@chakra-ui/react'
import { FaRegCommentAlt } from 'react-icons/fa'
import { PRDetails } from 'api/github'
import { formatTime, hoursAndDaysFormatter } from 'view/utils'
import ChartPageCard, {
  BIG_MAX_HEIGHT,
  SMALL_MAX_HEIGHT,
  WidgetSize,
} from 'view/components/ChartPageCard'

interface Props {
  size?: WidgetSize
  prDetails?: PRDetails
}

const getContents = (
  size: WidgetSize,
  openPrs: Exclude<Props['prDetails'], undefined>
) => {
  let prTableRows: Array<Array<React.ReactNode>> = []

  openPrs.forEach((pr) => {
    let statusString
    if (pr.status === 'merged') {
      statusString = `Merged ${formatTime(pr.merged_time)}`
    } else if (pr.status === 'approved') {
      statusString = `Approved ${formatTime(pr.approved_time)}`
    } else if (pr.status === 'closed') {
      statusString = `Closed ${formatTime(pr.closed_time)}`
    } else if (pr.status === 'inreview') {
      statusString = 'In Review'

      if (pr.most_recent_review_time) {
        statusString += ` (last review ${formatTime(
          pr.most_recent_review_time
        )}`
      }
    } else if (pr.status === 'waiting') {
      statusString = 'Waiting for Review'
    } else if (pr.status === 'open') {
      statusString = 'Open'
    } else if (pr.status === 'draft') {
      statusString = 'Draft'
    } else {
      statusString = 'Unknown'
    }

    type ReviewerWithCount = { name: string; count: number }
    const reviewerCounts: ReviewerWithCount[] = []
    Object.keys(pr.reviewers).forEach((reviewerName) => {
      reviewerCounts.push({
        name: reviewerName,
        count: pr.reviewers[reviewerName],
      })
    })
    reviewerCounts.sort((a, b) => a.count - b.count)
    const reviewerCountString =
      reviewerCounts.length !== 0
        ? reviewerCounts
            .map(
              (reviewerCount) =>
                `${reviewerCount.name} (${reviewerCount.count})`
            )
            .join(', ')
        : '-'

    const ageInDays = pr.age / 86400.0
    const ageString = hoursAndDaysFormatter(ageInDays, 'Unknown')

    const changesTitle = `${pr.additions_count} Additions, ${pr.deletions_count} Deletions`
    const commentTitle = `${pr.comments_count} Comments`
    prTableRows.push([
      <a href={pr.url} target="_blank" rel="noopener noreferrer">
        {pr.display_name}
      </a>,
      pr.author.name,
      reviewerCountString,
      ageString,
      <>
        <Flex>
          <Text color="green" title={changesTitle}>
            {pr.additions_count || 0}
          </Text>
          /
          <Text color="red" title={changesTitle}>
            -{pr.deletions_count || 0}
          </Text>
          <Box
            pl={1}
            as={FaRegCommentAlt}
            display="inline-block"
            title={commentTitle}
          ></Box>
          <Box pl={1} title={commentTitle}>
            {pr.comments_count}
          </Box>
        </Flex>
      </>,
      statusString,
    ])
  })

  return (
    <Box
      overflow="scroll"
      h={size === 'l' ? BIG_MAX_HEIGHT : SMALL_MAX_HEIGHT}
      mt={5}
    >
      <Table variant="striped" size="sm">
        <Thead>
          <Th>Pull Request</Th>
          <Th>Author</Th>
          <Th>Reviewers</Th>
          <Th>Age</Th>
          <Th>Details</Th>
          <Th>Status</Th>
        </Thead>
        <Tbody>
          {prTableRows.map((row) => (
            <Tr>
              {row.map((cellContents) => (
                <Td>{cellContents}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

const PRSummary = (props: Props) => {
  const prs = props.prDetails || []
  const openPrs = prs.filter((pr) => pr.is_open)
  let anyOpenPRs = openPrs.length !== 0
  const size = props.size || 'l'

  return (
    <ChartPageCard
      size={size}
      title={`${openPrs.length} Open PRs`}
      emptyTitle="No Open PRs!"
    >
      {anyOpenPRs ? getContents(size, openPrs) : null}
    </ChartPageCard>
  )
}

export default PRSummary
