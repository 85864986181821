import * as React from 'react'
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { useRepositories } from 'models/github/queries'
import Page from 'view/Page'
import Dropdown from 'view/components/Dropdown'
import HelpIcon from 'view/components/HelpIcon'

interface Props {
  id: string
  title: React.ReactNode
  helpTitle: React.ReactNode
  helpContents: React.ReactNode
  installationId: string
  setActiveRepository: (repo: string) => void
  activeRepo: string | null
}

interface RepositoryInfo {
  value: string
  label: string
}

const ChartPage = (props: React.PropsWithChildren<Props>) => {
  const repoQuery = useRepositories(props.installationId)
  const [repositoryOptions, setRepositoryOptions] = React.useState<
    RepositoryInfo[]
  >([])
  React.useEffect(() => {
    let repos: RepositoryInfo[] = []
    if (repoQuery.isSuccess && repoQuery.data && repoQuery.data.items) {
      repos = repoQuery.data.items.map((repo) => ({
        value: `${repo.ownerName}/${repo.name}`,
        label: `${repo.ownerName}/${repo.name}`,
      }))
    }
    setRepositoryOptions(repos)

    if (repos.length !== 0 && !props.activeRepo) {
      props.setActiveRepository(repos[0].value)
    }
  }, [repoQuery, props])

  return (
    <Page>
      <Box>
        <Box
          backgroundColor="white"
          borderRadius="lg"
          shadow="sm"
          px={3}
          py={5}
          mx={[0, 12]}
        >
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Heading
              size="md"
              as="h2"
              lineHeight="shorter"
              fontWeight="bold"
              fontFamily="heading"
            >
              {props.title}
              <HelpIcon
                id={`${props.id}-help`}
                title={props.helpTitle}
                contents={props.helpContents}
                placement="bottom"
              />
            </Heading>
          </Flex>
          <Stack shouldWrapChildren spacing={4} mx={4} mt={4} width="100%">
            <Stack shouldWrapChildren spacing={2} flexDirection="row">
              <Dropdown
                items={repositoryOptions}
                isLoading={repoQuery.isLoading}
                hasError={repoQuery.isError}
                onChange={props.setActiveRepository}
                value={props.activeRepo || ''}
                emptyString="No Repositories Found"
                label="Repo"
              />
            </Stack>
            <Stack shouldWrapChildren spacing={2}>
              {!props.activeRepo ? (
                <Text fontSize="l">Loading Your Repositories</Text>
              ) : null}
              {props.children}
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Page>
  )
}

export default ChartPage
