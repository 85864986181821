import * as React from 'react'
import { ReviewsByReviewer } from 'api/github'
import PieChart, { PieChartData } from 'view/components/PieChart'
import ChartPageCard, { WidgetSize } from 'view/components/ChartPageCard'

interface Props {
  size?: WidgetSize
  reviewers?: ReviewsByReviewer
}

const getContents = (reviewers: Exclude<Props['reviewers'], undefined>) => {
  let anyRequestedReviews = false
  let requestedByReviewerData: PieChartData[] = []

  Object.keys(reviewers).forEach((reviewerUsername) => {
    const reviewerData = reviewers[reviewerUsername]
    requestedByReviewerData.push({
      id: reviewerUsername,
      label: reviewerUsername,
      value: reviewerData.requested,
    })

    if (reviewerData.requested !== 0) {
      anyRequestedReviews = true
    }
  })

  return anyRequestedReviews ? (
    <PieChart data={requestedByReviewerData} />
  ) : null
}

const RequestedForReview = (props: Props) => {
  return (
    <ChartPageCard
      size={props.size || 's'}
      title="Requested for Review"
      emptyTitle="No Reviews Requested!"
    >
      {props.reviewers ? getContents(props.reviewers) : null}
    </ChartPageCard>
  )
}

export default RequestedForReview
