import * as React from 'react'
import { PRDetails } from 'api/github'
import PieChart, { PieChartData } from 'view/components/PieChart'
import ChartPageCard, { WidgetSize } from 'view/components/ChartPageCard'

interface Props {
  size?: WidgetSize
  prDetails?: PRDetails
  openOnly: boolean
}

const getContents = (prDetails: PRDetails) => {
  const authors: { [k: string]: number } = {}
  prDetails.forEach((pr) => {
    const authorName = pr.author.name
    if (!authors[authorName]) {
      authors[authorName] = 0
    }
    authors[authorName] += 1
  })

  const prsByAuthor: PieChartData[] = Object.keys(authors).map((authorName) => {
    return {
      id: authorName,
      label: authorName,
      value: authors[authorName],
    }
  })

  return prsByAuthor.length !== 0 ? <PieChart data={prsByAuthor} /> : null
}

const PRsByAuthor = (props: Props) => {
  const prs = props.prDetails || []
  const filteredPrs = props.openOnly ? prs.filter((pr) => pr.is_open) : prs
  const prLabel = props.openOnly ? 'Open PR' : 'PR'

  return (
    <ChartPageCard
      size={props.size || 's'}
      title={`${prLabel}s by Author`}
      emptyTitle={`No ${prLabel}s!`}
    >
      {filteredPrs ? getContents(filteredPrs) : null}
    </ChartPageCard>
  )
}

export default PRsByAuthor
