import { createSlice } from '@reduxjs/toolkit'
import Api from 'api'
import { AppDispatch, AppThunk } from '../store'
import { RootState } from '../rootReducer'
import { randomString } from 'utils'
import { GITHUB_APP_CLIENT_ID, GITHUB_APP_REDIRECT_URL } from 'config'

export type GITHUB_AUTH_CODE = string
const GITHUB_STATE_STORAGE_KEY = '__mergesquad_github_auth_state'

export const githubSlice = createSlice({
  name: 'github',
  initialState: {
    isSecretStateValid: null,
    isCodeValid: null,
    isCodeInFlight: false,
    codeConversionSuccess: false,
  },
  reducers: {
    setIsCodeValid: (state, action) => {
      state.isCodeValid = action.payload
    },

    setIsSecretStateValid: (state, action) => {
      state.isSecretStateValid = action.payload
    },

    setIsCodeInFlight: (state, action) => {
      state.isCodeInFlight = action.payload
    },

    setCodeConversionSuccess: (state, action) => {
      state.codeConversionSuccess = action.payload
    },
  },
})

export const {
  setIsCodeValid,
  setIsCodeInFlight,
  setIsSecretStateValid,
  setCodeConversionSuccess,
} = githubSlice.actions

const _setSecretState = (secretState: any) => {
  if (localStorage) {
    localStorage.setItem(GITHUB_STATE_STORAGE_KEY, JSON.stringify(secretState))
  }
}

export const prepareForLogin = (return_location?: string | null) => {
  const secretState = {
    nonce: randomString(32),
    return_location,
  }
  _setSecretState(secretState)

  const githubLoginUrl = new URL('https://github.com/login/oauth/authorize')
  githubLoginUrl.searchParams.set('client_id', GITHUB_APP_CLIENT_ID)
  githubLoginUrl.searchParams.set('redirect_url', GITHUB_APP_REDIRECT_URL)
  githubLoginUrl.searchParams.set('state', JSON.stringify(secretState))

  return githubLoginUrl
}

export const isSecretStateValid = (secretState: string | null) => {
  if (localStorage) {
    return localStorage.getItem(GITHUB_STATE_STORAGE_KEY) === secretState
  }

  // if user's browser does not support localstorage we just give up on this extra
  // bit of security -- alternatively could block login with this browser but the
  // risk feels small
  return true
}

export const convertCodeToAuth = (
  code: GITHUB_AUTH_CODE | null,
  secretState: string | null
): AppThunk => async (dispatch) => {
  if (!code) {
    dispatch(setIsCodeValid(false))
    return
  }

  const isValid = isSecretStateValid(secretState)
  dispatch(setIsSecretStateValid(isValid))
  if (!isValid) {
    return
  }

  _setSecretState('')
  _convertCodeToAuth(code, dispatch)
}

const _convertCodeToAuth = async (
  code: GITHUB_AUTH_CODE,
  dispatch: AppDispatch
) => {
  try {
    dispatch(setIsCodeValid(true))
    dispatch(setIsCodeInFlight(true))
    await Api.login.github(code)
    dispatch(setCodeConversionSuccess(true))
  } catch (e) {
    dispatch(setCodeConversionSuccess(false))
  }

  dispatch(setIsCodeInFlight(false))
}

// selectors
export const selectIsValid = (state: RootState) =>
  state.authGithub.isCodeInFlight && state.authGithub.isSecretStateValid
export const selectIsInFlight = (state: RootState) =>
  state.authGithub.isCodeInFlight
export const selectCodeConversionSuccess = (state: RootState) =>
  state.authGithub.codeConversionSuccess

export default githubSlice.reducer
