import * as React from 'react'
import { ReviewStatuses } from 'api/github'
import PieChart, { PieChartData } from 'view/components/PieChart'
import ChartPageCard, { WidgetSize } from 'view/components/ChartPageCard'
import { STATUS_COLORS } from 'view/constants'

interface Props {
  size?: WidgetSize
  statuses?: ReviewStatuses
  includeClosed?: boolean
}

const getContents = (
  statuses: Exclude<Props['statuses'], undefined>,
  includeClosed: boolean
) => {
  let anyPrStatusData = false
  const prStatusData: PieChartData[] = [
    {
      id: 'Open',
      label: 'Open',
      value: statuses.open,
      color: STATUS_COLORS.open,
    },
    {
      id: 'Approved',
      label: 'Approved',
      value: statuses.approved,
      color: STATUS_COLORS.approved,
    },
    {
      id: 'Waiting',
      label: 'Waiting for Review',
      value: statuses.waiting,
      color: STATUS_COLORS.waiting,
    },
    {
      id: 'In Review',
      label: 'In Review',
      value: statuses.inreview,
      color: STATUS_COLORS.inreview,
    },
  ]

  if (includeClosed) {
    prStatusData.push({
      id: 'Merged',
      label: 'Merged',
      value: statuses.merged,
      color: STATUS_COLORS.merged,
    })

    prStatusData.push({
      id: 'Closed',
      label: 'Closed',
      value: statuses.closed,
      color: STATUS_COLORS.closed,
    })
  }

  prStatusData.forEach((statusInfo) => {
    if (statusInfo.value !== 0) {
      anyPrStatusData = true
    }
  })

  return anyPrStatusData ? <PieChart data={prStatusData} /> : null
}

const PRStatus = (props: Props) => {
  const prLabel = props.includeClosed ? 'PR' : 'Unmerged PR'
  return (
    <ChartPageCard
      size={props.size || 's'}
      title={`${prLabel} Status`}
      emptyTitle={`No ${prLabel}s!`}
    >
      {props.statuses
        ? getContents(props.statuses, !!props.includeClosed)
        : null}
    </ChartPageCard>
  )
}

export default PRStatus
