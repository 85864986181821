/***
 *
 *  TODO:
 * no report links
 * why does route with installationId always go to install selection instead of dashboard?
 * the chart url does not ever match when nested - why?
 */

import 'preact/devtools'

import * as React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Box, Heading, Text, ChakraProvider, CSSReset } from '@chakra-ui/react'
import { ReactQueryConfigProvider } from 'react-query'
import theme from './theme'

import LandingPage from './Landing'
import LandingFeaturesPage from './LandingFeatures'
import LoginPage from './Login'
import UnsupportedLogin from './components/UnsupportedLogin'
import GithubLogin from './github/GithubLogin'
import GithubSelectInstallation from './github/GithubSelectInstallation'
import GithubDashboard from './github/GithubDashboard'
import GithubReviews from './github/GithubReviews'
import GithubOverview from './github/GithubOverview'
import NavBar from './components/NavBar'
import Footer from './components/Footer'

import './App.css'

interface NavLink {
  label: string
  href: string
  requiresInstallation?: boolean
}

const navLinks: { [section: string]: NavLink[] } = {
  loggedOut: [
    { label: 'Features', href: '/features' },
    // { label: 'Marketing link 2', href: '/' },
    // { label: 'Marketing link 3', href: '/' },
  ],
  loggedIn: [
    {
      label: 'Overview',
      href: 'overview',
      requiresInstallation: true,
    },
    {
      label: 'Reviews',
      href: 'reviews',
      requiresInstallation: true,
    },
    // { label: 'Merge Time', href: 'mergetime', requiresInstallation: true },
    // { label: 'PR Lifecycle', href: 'prlifecycle', requiresInstallation: true },
  ],
}
interface PageWrapperProps {
  includeInstallationLinks?: boolean
  fixedHeader?: boolean
  showLogout?: boolean
  showRegister?: boolean
  links: NavLink[]
}

const PageWrapper = (props: React.PropsWithChildren<PageWrapperProps>) => {
  const links = props.links.filter(
    (link) => !link.requiresInstallation || props.includeInstallationLinks
  )

  return (
    <>
      <NavBar
        title="MergeSquad"
        titleLinkHref="/"
        links={links}
        registerLink="/login"
        logoutLink="/logout"
        showLogout={props.showLogout}
        showRegister={props.showRegister}
        fixedHeader={props.fixedHeader}
      />
      <Box mt={props.fixedHeader ? 32 : 0}>{props.children}</Box>
      <Footer links={links} />
    </>
  )
}

const LoggedInPage = (
  props: React.PropsWithChildren<
    Pick<PageWrapperProps, 'includeInstallationLinks'>
  >
) => (
  <PageWrapper
    showLogout={true}
    links={navLinks.loggedIn}
    includeInstallationLinks={props.includeInstallationLinks}
  >
    {props.children}
  </PageWrapper>
)

const LoggedOutPage = (
  props: React.PropsWithChildren<
    Pick<PageWrapperProps, 'includeInstallationLinks'>
  >
) => (
  <PageWrapper
    showRegister={true}
    showLogout={false}
    links={navLinks.loggedOut}
    fixedHeader={true}
  >
    {props.children}
  </PageWrapper>
)

const queryConfig = { queries: { staleTime: 1000 } }

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ReactQueryConfigProvider config={queryConfig}>
        <CSSReset />
        <Router>
          <Switch>
            <Route path="/thirdparty/github">
              <LoggedOutPage>
                <GithubLogin />
              </LoggedOutPage>
            </Route>
            <Route path="/thirdparty">
              <LoggedOutPage>
                <UnsupportedLogin />
              </LoggedOutPage>
            </Route>

            <Route path="/platforms/github/:installationId/overview">
              <LoggedInPage includeInstallationLinks={true}>
                <GithubOverview />
              </LoggedInPage>
            </Route>
            <Route path="/platforms/github/:installationId/reviews">
              <LoggedInPage includeInstallationLinks={true}>
                <GithubReviews />
              </LoggedInPage>
            </Route>
            <Route path="/platforms/github">
              <Switch>
                {/* why doesnt this ever match?
                <Route path="/:installationId/reviews">
                  <GithubReviews />
                </Route> */}
                {/* this ALSO doesn't work right - always goes to installation selection O_O*/}
                <Route exact path="/:installationId">
                  <LoggedInPage includeInstallationLinks={true}>
                    <GithubDashboard />
                  </LoggedInPage>
                </Route>
                <Route path="">
                  <LoggedOutPage>
                    <GithubSelectInstallation />
                  </LoggedOutPage>
                </Route>
              </Switch>
            </Route>

            <Route path="/">
              <LoggedOutPage>
                <Switch>
                  <Route exact path="/login">
                    <LoginPage />
                  </Route>
                  <Route exact path="/logout">
                    <LoginPage loggedOut={true} />
                  </Route>
                  <Route exact path="/features">
                    <LandingFeaturesPage />
                  </Route>
                  <Route path="*">
                    <LandingPage />
                  </Route>
                </Switch>
              </LoggedOutPage>
            </Route>

            <Route path="">
              <Box textAlign="center">
                <Heading>
                  404 - Oops! We couldn't find the page you were looking for
                </Heading>
                <Text fontSize="sm" color="gray.600">
                  We're also sorry we didn't spend any time making this page
                  look nicer.
                </Text>
              </Box>
            </Route>
          </Switch>
        </Router>
      </ReactQueryConfigProvider>
    </ChakraProvider>
  )
}

export default App
