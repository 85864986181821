// TODO: errors just show up as 'no PRs'

import * as React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useHistory, useLocation, useParams } from 'react-router'
import { queryStringAdd } from 'utils'
import { formatTime } from 'view/utils'
// TODO: decouple this
import { AuthRequiredError } from 'api/utils'
import { Calculator } from 'api/types'
// TODO: move this
import { isApiSuccess, GetGeneralPayload } from 'api/github'
import { useGeneral } from 'models/github/queries'
import ChartPage from 'view/components/ChartPage'
import ChartPageContents from 'view/components/ChartPageContents'
import PRStatus from 'view/components/statcards/PRStatus'
import Milestones from 'view/components/statcards/Milestones'
import PRSummary from 'view/components/statcards/PRSummary'
import PRsByAuthor from 'view/components/statcards/PRsByAuthor'
import RequestedForReview from 'view/components/statcards/RequestedForReview'
import CodeChangeByPR from 'view/components/statcards/CodeChangeByPR'

const REFRESHING_DELAY = 5000
const MILESTONE_PR_COUNT = 20

const OverviewPage = () => {
  const { installationId } = useParams<{ installationId: string }>()
  const history = useHistory()
  const location = useLocation()

  // TODO: store these in redux so they persist across chart pages
  // TODO: also localstorage?
  const repoInLocation = new URLSearchParams(location.search)
  const [activeRepo, _setActiveRepo] = React.useState<string | null>(
    repoInLocation.get('repository') || null
  )

  const setActiveRepository = (repo: string) => {
    const newLocation = location
    newLocation.search = queryStringAdd(location.search, 'repository', repo)
    history.push(newLocation)
    _setActiveRepo(repo)
  }

  interface RepoInfo {
    name: string
    ownerName: string
  }
  const [activeRepoInfo, setActiveRepoInfo] = React.useState<RepoInfo | null>(
    null
  )
  React.useEffect(() => {
    const [repoOwnerName, repoName] = activeRepo
      ? activeRepo.split('/')
      : [null, null]
    setActiveRepoInfo(
      repoName
        ? {
            name: repoName || '',
            ownerName: repoOwnerName || '',
          }
        : null
    )
  }, [activeRepo])

  const [shouldPoll, setShouldPoll] = React.useState(false)

  const ownerName = (activeRepoInfo && activeRepoInfo.ownerName) || undefined
  const name = (activeRepoInfo && activeRepoInfo.name) || undefined
  const calculators: Calculator[] = [
    'status',
    'reviews_by_reviewer',
    'pr_lifecycle',
    'first_review_turnaround',
    'pr_details',
  ]
  const overviewQuery = useGeneral(ownerName, name, calculators, {
    enabled: activeRepoInfo !== null,
    refetchInterval: shouldPoll ? REFRESHING_DELAY : undefined,
  })

  // TODO: how to do this in a generic, clean way?
  if (
    overviewQuery.isError &&
    overviewQuery.error instanceof AuthRequiredError
  ) {
    history.push(
      `/login?auth_expired=true&return_location=${location.pathname}${location.search}`
    )
    return null
  }

  let overviewData = null
  let hasResults = false
  let isRefreshing = false
  let lastSyncedString = ''
  if (overviewQuery.isSuccess && isApiSuccess(overviewQuery.data)) {
    overviewData = overviewQuery.data.data
    isRefreshing = !!overviewQuery.data.refreshing
    lastSyncedString = overviewQuery.data.last_mergesquad_sync
      ? formatTime(overviewQuery.data.last_mergesquad_sync)
      : ''
    hasResults = !!overviewQuery.data.has_data

    setShouldPoll(isRefreshing)
  }

  return (
    <ChartPage
      id="overview-page"
      title="Overview"
      helpTitle="Overview"
      helpContents={
        <>
          <Text>
            This is a general view of the things going on in your repository.
            Start here for a good overview of how things are going.
          </Text>
        </>
      }
      installationId={installationId}
      setActiveRepository={setActiveRepository}
      activeRepo={activeRepo}
    >
      <ChartPageContents
        isError={overviewQuery.isError}
        isLoading={overviewQuery.isLoading}
        isRefreshing={isRefreshing}
        lastSyncedString={lastSyncedString}
      >
        {!overviewData || !hasResults || !isApiSuccess(overviewQuery.data) ? (
          <Flex
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-around"
          >
            <Box textAlign="center">
              <Text p={4} fontSize="md">
                No PRs with reviews found for this repository during the chart's
                time frame!
              </Text>
              {isRefreshing ? (
                <Text fontSize="xs">Checking GitHub for updates...</Text>
              ) : null}
            </Box>
          </Flex>
        ) : (
          getContents(overviewData)
        )}
      </ChartPageContents>
    </ChartPage>
  )
}
export default OverviewPage

const getContents = (overviewData: GetGeneralPayload) => {
  return (
    <Box>
      <Flex
        spacing={10}
        wrap="wrap"
        justifyContent="center"
        alignContent="space-around"
      >
        <PRStatus statuses={overviewData.status} includeClosed={true} />
        {/* TODO: PRs created and closed this week */}
        <PRsByAuthor prDetails={overviewData.pr_details} openOnly={true} />
        <RequestedForReview reviewers={overviewData.reviews_by_reviewer} />
        <Milestones
          lifecycles={overviewData.pr_lifecycle}
          maxMRCount={MILESTONE_PR_COUNT}
        />
        <CodeChangeByPR prDetails={overviewData.pr_details} />
        <PRSummary prDetails={overviewData.pr_details} />
      </Flex>
    </Box>
  )
}
