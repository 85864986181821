import * as React from 'react'
import { Flex } from '@chakra-ui/react'
import Page from './Page'

import { sectionPadding } from './Landing'
import SideBySideSection, {
  FlippedOnMobile,
  TextSideBox,
  ImageSideBox,
} from './components/Landing/SideBySideSection'
import PricingSection from './components/Landing/PricingSection'
import thankYouImage from './images/undraw_super_thank_you_obwk.svg'
import pieChartImage from './images/undraw_pie_chart_6efe.svg'
import commitsImage from './images/undraw_developer_activity_bv83.svg'
// import notificationsImage from './images/undraw_new_notifications_fhvw.svg'

const analyticsDetails = {
  title: 'Actionable Repository Analytics',
  details: `
    We believe you can't improve what you can't measure. Important high-level
    metrics let you visualize your overall dev team health (initial review
    turnaround, time to merge, merge lag, and reviewer workload), plus
    detailed views to drill down and identify how to improve.
  `,
  image: pieChartImage,
  imageAlt: 'person holding a piece of a pie chart',
}

// const notificationDetails = {
//   title: 'Real-Time Notifications',
//   details: [
//     `
//       Replace checking emails and reloading bookmarked pages with chat
//       notifications in the system your company is already using. When
//       someone requests your review or does something significant with
//       one of your branches, we'll let you know.
//     `,
//     `
//       This is probably the easiest and fastest way to speed up the entire
//       review cycle while simultaneously giving some focus and time back
//       to every one of your developers.
//     `,
//   ],
//   image: notificationsImage,
//   imageAlt: 'person next to notifications',
// }

const batteriesIncludedDetails = {
  title: 'Batteries Included',
  details: `
    Connect to your existing repository and instantly start seeing useful
    metrics with reasonable defaults. Customize from there to make
    MergeSquad perfectly match your team and your workflow.
  `,
  image: commitsImage,
  imageAlt: 'a software developer making commits',
}

const yourTeamWillThankYouDetails = {
  title: 'Your Team Will Thank You',
  details: [
    `
      Empower your team to self-evaluate and improve without interrupting their
      work or forcing them to change tools.
    `,
    `
      Collaborate better by removing the annoying parts and letting everyone
      focus on the most important things without having to worry about
      dropping the ball.
    `,
  ],
  image: thankYouImage,
  imageAlt: 'person with hearts around them',
}

const LandingFeaturesPage = () => {
  return (
    <Page>
      <Flex
        width="full"
        height="100%"
        align="center"
        justifyContent="center"
        flexDirection="column"
        padding={0}
        bg="white"
      >
        <SideBySideSection
          sectionPadding={sectionPadding}
          left={<ImageSideBox {...analyticsDetails} />}
          right={<TextSideBox {...analyticsDetails} />}
        />

        {/* <FlippedOnMobile
          sectionPadding={sectionPadding}
          left={<TextSideBox {...notificationDetails} />}
          right={<ImageSideBox {...notificationDetails} />}
        /> */}

        <FlippedOnMobile
          sectionPadding={sectionPadding}
          left={<TextSideBox {...batteriesIncludedDetails} />}
          right={<ImageSideBox {...batteriesIncludedDetails} />}
        />

        <SideBySideSection
          sectionPadding={sectionPadding}
          left={<ImageSideBox {...yourTeamWillThankYouDetails} />}
          right={<TextSideBox {...yourTeamWillThankYouDetails} />}
        />

        <PricingSection />
      </Flex>
    </Page>
  )
}

export default LandingFeaturesPage
