import * as React from 'react'
import { BoxProps, Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

const StyledHamburger = styled(Box)`
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    width: 100%;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-of-type(1) {
    top: 0;
  }

  span:nth-of-type(2),
  span:nth-of-type(3) {
    top: 0.375rem;
  }

  span:nth-of-type(4) {
    top: 0.75rem;
  }

  &.open {
    span:nth-of-type(1) {
      top: 0;
      width: 0%;
      left: 50%;
    }

    span:nth-of-type(2) {
      transform: rotate(45deg);
    }

    span:nth-of-type(3) {
      transform: rotate(-45deg);
    }

    span:nth-of-type(4) {
      top: 1.125rem;
      width: 0%;
      left: 50%;
    }
  }
`

interface HamburgerProps {
  open: boolean
}

const Hamburger = (props: HamburgerProps & BoxProps) => (
  <StyledHamburger
    id="nav-icon"
    className={props.open ? 'open' : ''}
    width="1rem"
    height="1rem"
    margin="auto"
  >
    <Box as="span" height="0.125rem" bg={props.color || props.bg} />
    <Box as="span" height="0.125rem" bg={props.color || props.bg} />
    <Box as="span" height="0.125rem" bg={props.color || props.bg} />
    <Box as="span" height="0.125rem" bg={props.color || props.bg} />
  </StyledHamburger>
)

export default Hamburger
