// TODO: errors just show up as 'no PRs'

import * as React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useHistory, useLocation, useParams } from 'react-router'
import { queryStringAdd } from 'utils'
import { formatTime } from 'view/utils'
// TODO: decouple this
import { AuthRequiredError } from 'api/utils'
// TODO: move this
import { isApiSuccess, GetReviewsPayload } from 'api/github'
import { useReviews } from 'models/github/queries'
import ChartPage from 'view/components/ChartPage'
import ChartPageContents from 'view/components/ChartPageContents'
import PRStatus from 'view/components/statcards/PRStatus'
import RequestedForReview from 'view/components/statcards/RequestedForReview'
import ReviewsCompleted from 'view/components/statcards/ReviewsCompleted'
import PRStatusByReviewer from 'view/components/statcards/PRStatusByReviewer'
import Milestones from 'view/components/statcards/Milestones'
import PRTurnaroundByWeek from 'view/components/statcards/PRTurnaroundByWeek'
import PRSummary from 'view/components/statcards/PRSummary'

const REFRESHING_DELAY = 5000
const MILESTONE_PR_COUNT = 10

const ReviewsPage = () => {
  const { installationId } = useParams<{ installationId: string }>()
  const history = useHistory()
  const location = useLocation()

  // TODO: store these in redux so they persist across chart pages
  // TODO: also localstorage?
  const repoInLocation = new URLSearchParams(location.search)
  const [activeRepo, _setActiveRepo] = React.useState<string | null>(
    repoInLocation.get('repository') || null
  )

  const setActiveRepository = (repo: string) => {
    const newLocation = location
    newLocation.search = queryStringAdd(location.search, 'repository', repo)
    history.push(newLocation)
    _setActiveRepo(repo)
  }

  interface RepoInfo {
    name: string
    ownerName: string
  }
  const [activeRepoInfo, setActiveRepoInfo] = React.useState<RepoInfo | null>(
    null
  )
  React.useEffect(() => {
    const [repoOwnerName, repoName] = activeRepo
      ? activeRepo.split('/')
      : [null, null]
    setActiveRepoInfo(
      repoName
        ? {
            name: repoName || '',
            ownerName: repoOwnerName || '',
          }
        : null
    )
  }, [activeRepo])

  const [shouldPoll, setShouldPoll] = React.useState(false)
  const reviewsQuery = useReviews(
    activeRepoInfo?.ownerName,
    activeRepoInfo?.name,
    {
      enabled: activeRepoInfo !== null,
      refetchInterval: shouldPoll ? REFRESHING_DELAY : undefined,
    }
  )

  // TODO: how to do this in a generic, clean way?
  if (reviewsQuery.isError && reviewsQuery.error instanceof AuthRequiredError) {
    history.push(
      `/login?auth_expired=true&return_location=${location.pathname}${location.search}`
    )
    return null
  }

  let reviewsData = null
  let hasResults = false
  let isRefreshing = false
  let lastSyncedString = ''
  if (reviewsQuery.isSuccess && isApiSuccess(reviewsQuery.data)) {
    reviewsData = reviewsQuery.data.data
    isRefreshing = !!reviewsQuery.data.refreshing
    lastSyncedString = reviewsQuery.data.last_mergesquad_sync
      ? formatTime(reviewsQuery.data.last_mergesquad_sync)
      : ''
    hasResults = !!reviewsQuery.data.has_data

    setShouldPoll(isRefreshing)
  }

  return (
    <ChartPage
      id="reviews-page"
      title="Reviews"
      helpTitle="Reviews"
      helpContents={
        <>
          <Text>
            This is a high level view of your review pipeline designed to give
            you early indicators for things like stuck reviews, overloaded
            reviewers, and other bottlenecks in your dev process.
          </Text>
        </>
      }
      installationId={installationId}
      setActiveRepository={setActiveRepository}
      activeRepo={activeRepo}
    >
      <ChartPageContents
        isError={reviewsQuery.isError}
        isLoading={reviewsQuery.isLoading}
        isRefreshing={isRefreshing}
        lastSyncedString={lastSyncedString}
      >
        {!reviewsData || !hasResults || !isApiSuccess(reviewsQuery.data) ? (
          <Flex
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-around"
          >
            <Box textAlign="center">
              <Text p={4} fontSize="md">
                No PRs with reviews found for this repository during the chart's
                time frame!
              </Text>
              {isRefreshing ? (
                <Text fontSize="xs">Checking GitHub for updates...</Text>
              ) : null}
            </Box>
          </Flex>
        ) : (
          getContents(reviewsData)
        )}
      </ChartPageContents>
    </ChartPage>
  )
}
export default ReviewsPage

const getContents = (reviewsData: GetReviewsPayload) => {
  return (
    <Box>
      <Flex
        spacing={10}
        wrap="wrap"
        justifyContent="center"
        alignContent="space-around"
      >
        <PRStatus statuses={reviewsData.status} includeClosed={false} />
        <RequestedForReview reviewers={reviewsData.reviews_by_reviewer} />
        <ReviewsCompleted reviewers={reviewsData.reviews_by_reviewer} />

        <PRStatusByReviewer reviewers={reviewsData.reviews_by_reviewer} />
        <Milestones
          lifecycles={reviewsData.pr_lifecycle}
          maxMRCount={MILESTONE_PR_COUNT}
        />
        <PRTurnaroundByWeek
          turnaroundByWeek={reviewsData.first_review_turnaround}
        />
        <PRSummary prDetails={reviewsData.pr_details} />
      </Flex>
    </Box>
  )
}
