import { get, post, AuthRequiredError } from './utils'
import {
  GithubInstallation,
  GithubRepository,
  GithubTeam,
  MergeRequestMilestone,
  MergeRequestStatus,
  Calculator,
} from './types'

export type GetInstallationsResponse = {
  items: GithubInstallation[]
}

export async function getInstallations(): Promise<GetInstallationsResponse> {
  return get('github/installations').then((result) => ({
    items: result.items,
  }))
}

export type GetRepositoriesResponse = {
  items: GithubRepository[]
}

export async function getRepositories(
  installationId: string
): Promise<GetRepositoriesResponse> {
  return get(`github/${installationId}/repositories`).then((result) => ({
    items: result.items,
  }))
}

export type GetTeamsResponse = {
  items: GithubTeam[]
}

export async function getTeams(
  ownerName: string,
  repositoryName: string
): Promise<GetTeamsResponse> {
  return get(`github/repository/${ownerName}/${repositoryName}/teams`).then(
    (result) => ({
      items: result.items,
    })
  )
}

export async function processRepository(
  ownerName: string,
  repositoryName: string
): Promise<{ success: string }> {
  return post(`github/repository/${ownerName}/${repositoryName}/process`)
}

export async function getStats(
  ownerName: string,
  repositoryName: string
): Promise<{}> {
  return get(`github/repository/${ownerName}/${repositoryName}/stats`)
}

export type TurnaroundBucket = {
  [bucket: string]: number
}

export interface GetTurnaroundPayload {
  turnaround: { [week: string]: TurnaroundBucket }
}

export function isApiSuccess<T>(
  response?: ApiResponse<T>
): response is ApiSuccess<T> {
  return !!(response && 'success' in response && response.success)
}

interface ApiBaseResponse {
  has_data?: boolean
  refreshing?: boolean
  last_mergesquad_sync?: string
}

export interface ApiSuccess<T> extends ApiBaseResponse {
  success: true
  data: T
}

export interface ApiFailure extends ApiBaseResponse {
  success: false
  data: {}
}

export type ApiResponse<T> = ApiSuccess<T> | ApiFailure | AuthRequiredError

export async function getTurnaround(
  ownerName: string,
  repositoryName: string
): Promise<ApiResponse<GetTurnaroundPayload>> {
  return get(`github/repository/${ownerName}/${repositoryName}/turnaround`)
}

type ReviewerKeys =
  | MergeRequestStatus
  | 'requested'
  | 'reviewed'
  | 'avg_first_review_turnaround'
  | 'avg_mr_approved_turnaround'

// TODO: move this out of 'github'
export type ReviewStatuses = {
  [k in MergeRequestStatus]: number
}

export type ReviewsByReviewer = {
  [username: string]: { [k in ReviewerKeys]: number }
}

export type PRLifecycle = {
  [prLabel: string]: {
    [k in MergeRequestMilestone]: number
  }
}

export type PRTurnaroundBuckets = {
  [mondayTimestamp: string]: {
    [bucket: string]: number
  }
}

export type PRDetails = Array<{
  title: string
  author: {
    name: string
  }
  reviewers: {
    [name: string]: number
  }
  url: string
  status: MergeRequestStatus
  age: number
  display_name: string
  most_recent_review_time: string
  created_time: string
  approved_time: string
  merged_time: string
  closed_time: string
  is_open: boolean
  is_closed: boolean
  is_merged: boolean
  additions_count: number
  deletions_count: number
  files_changed_count: number
  comments_count: number
}>

export interface GetReviewsPayload {
  status: ReviewStatuses
  reviews_by_reviewer: ReviewsByReviewer
  pr_lifecycle: PRLifecycle
  first_review_turnaround: PRTurnaroundBuckets
  pr_details: PRDetails
}

export async function getReviews(
  ownerName: string,
  repositoryName: string
): Promise<ApiResponse<GetReviewsPayload>> {
  return get(`github/repository/${ownerName}/${repositoryName}/reviews`)
}

export interface GetGeneralPayload {
  status?: ReviewStatuses
  reviews_by_reviewer?: ReviewsByReviewer
  pr_lifecycle?: PRLifecycle
  pr_details?: PRDetails
}

type ParamMap = {
  [k in Calculator]: '1'
}
export async function getGeneral(
  ownerName: string,
  repositoryName: string,
  calculators: Calculator[]
): Promise<ApiResponse<GetGeneralPayload>> {
  const paramMap = calculators.reduce(
    (map: ParamMap, calculator: Calculator) => {
      map[calculator] = '1'
      return map
    },
    {} as ParamMap
  )
  const params = new URLSearchParams(paramMap)

  return get(
    `github/repository/${ownerName}/${repositoryName}/general?${params.toString()}`
  )
}

export default {
  getInstallations,
  getRepositories,
  getTeams,
  getStats,
  getTurnaround,
  processRepository,
  getReviews,
  getGeneral,
}
