import * as React from 'react'
import { ResponsiveBar } from '@nivo/bar'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export type BarChartData = {
  key: string
  [k: string]: number | string
}

interface Props {
  data: BarChartData[]
  yAxisLabel: string
  xAxisLabel: string
  keys: string[]
  colorMap?: { [key: string]: string }
}

const BarChart = (props: Props) => (
  <ResponsiveBar
    data={props.data}
    keys={props.keys}
    indexBy="key"
    margin={{ top: 50, right: 130, bottom: 85, left: 60 }}
    padding={0.3}
    //    colors={{ scheme: 'red_yellow_green' }}
    colors={({ id, data }) => {
      if (data[`${id}Color`]) {
        return data[`${id}Color`]
      }

      if (props.colorMap && props.colorMap[id]) {
        return props.colorMap[id]
      }

      // default color?
      return '#e0e0e0'
    }}
    // defs={[
    //     {
    //         id: 'dots',
    //         type: 'patternDots',
    //         background: 'inherit',
    //         color: '#38bcb2',
    //         size: 4,
    //         padding: 1,
    //         stagger: true
    //     },
    //     {
    //         id: 'lines',
    //         type: 'patternLines',
    //         background: 'inherit',
    //         color: '#eed312',
    //         rotation: -45,
    //         lineWidth: 6,
    //         spacing: 10
    //     }
    // ]}
    // fill={[
    //     {
    //         match: {
    //             id: 'fries'
    //         },
    //         id: 'dots'
    //     },
    //     {
    //         match: {
    //             id: 'sandwich'
    //         },
    //         id: 'lines'
    //     }
    // ]}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: props.xAxisLabel,
      legendPosition: 'middle',
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: props.yAxisLabel,
      legendPosition: 'middle',
      legendOffset: -40,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
)

export default BarChart
