import * as React from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Avatar,
  Box,
  Flex,
  Heading,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useInstallations } from 'models/github/queries'
import Page from 'view/Page'
import Link from 'view/components/Link'

const Contents = () => {
  const { isLoading, error, data } = useInstallations()

  if (isLoading) {
    return (
      <Box>
        <Text fontSize="md">
          <Spinner size="sm" mr={4} />
          Loading Installations
        </Text>
      </Box>
    )
  }

  if (error || !data) {
    return (
      <>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Failed to Access Installations</AlertTitle>
          <AlertDescription>We failed to access your account</AlertDescription>
        </Alert>
        <Text fontSize="md">
          Something went sideways while we were trying to figure out which
          repositories you have access to. Please try again and let us know if
          you're stuck.
        </Text>
      </>
    )
  }

  return (
    <>
      {data.items.length === 0 ? (
        <Flex align="center" justify="space-around">
          <Box>
            <Text fontSize="md">
              We didn't find any github repositories/organizations with
              MergeSquad installed.
            </Text>
            <Text fontSize="md">
              Now might be a good time to{' '}
              <Link
                to="https://github.com/apps/mergesquad"
                isExternal
                fontWeight="bold"
                textDecoration="underline"
              >
                Go Add The App
              </Link>{' '}
              to something!
            </Text>
          </Box>
        </Flex>
      ) : null}
      {data.items.map((install) => (
        <Flex p={10} align="center" justify="space-around">
          {/* <Link to={`/platforms/github/${install.installationId}`}> */}
          <Link to={`/platforms/github/${install.installationId}/reviews`}>
            <Stack>
              <Avatar
                size="xl"
                name={install.accountLogin}
                src={install.accountAvatarUrl}
              />
              <Text>{install.accountLogin}</Text>
            </Stack>
          </Link>
        </Flex>
      ))}
    </>
  )
}

const TestPage = () => {
  return (
    <Page>
      <Box>
        <Box
          backgroundColor="white"
          borderRadius="lg"
          shadow="sm"
          px={3}
          py={5}
          m={12}
        >
          <Flex
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-around"
          >
            <Heading
              size="md"
              as="h2"
              lineHeight="shorter"
              fontWeight="bold"
              fontFamily="heading"
              textAlign="center"
            >
              Select Account
            </Heading>
          </Flex>
          <Stack shouldWrapChildren spacing={4} ml={4} mt={4}>
            <Stack shouldWrapChildren spacing={2}>
              <Box textAlign="center">
                <Contents />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Page>
  )
}

export default TestPage
