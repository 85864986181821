// TODO: expose way more chart options as props
import * as React from 'react'
import { ResponsivePie } from '@nivo/pie'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export type PieChartData = {
  id: string
  label: string
  value: number
  color?: string
}

interface Props {
  data: PieChartData[]
  showLegends?: boolean
}

const PieChart = (props: Props) => {
  if (!props.data || !props.data[0]) {
    return null
  }

  const useDataColor = !!props.data[0].color
  const colorProps = useDataColor ? { colors: { datum: 'data.color' } } : {}

  return (
    <ResponsivePie
      data={props.data}
      margin={{ top: 40, right: 60, bottom: 80, left: 60 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      {...colorProps}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextColor="#333333"
      radialLabelsLinkColor={{ from: 'color' }}
      sliceLabelsSkipAngle={10}
      sliceLabelsTextColor="#333333"
      legends={
        props.showLegends
          ? [
              {
                // anchor: 'right',
                // direction: 'column',
                // translateX: 80,
                // translateY: 0,
                anchor: 'bottom',
                direction: 'row',
                translateX: 30,
                translateY: 35,
                itemsSpacing: 0,
                justify: false,
                itemWidth: 65,
                itemHeight: 14,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000',
                    },
                  },
                ],
              },
            ]
          : undefined
      }
    />
  )
}

export default PieChart
