import * as React from 'react'
import { Box, BoxProps, Grid, Heading, Image, Text } from '@chakra-ui/react'

export interface Props {
  sectionPadding: Array<number | null>
  left: React.ReactNode
  right: React.ReactNode
  boxProps?: BoxProps
}

const SideBySideSection: React.FC<Props> = (props) => (
  <Box padding={props.sectionPadding} {...props.boxProps}>
    <Grid templateColumns={['1fr', null, 'repeat(2, 1fr)']}>
      {props.left}
      {props.right}
    </Grid>
  </Box>
)

export interface TextSideBoxProps {
  title: string
  details: string | string[]
}

export interface ImageSideBoxProps {
  image: string
  imageAlt: string
}

export const TextSideBox: React.FC<TextSideBoxProps> = ({ title, details }) => (
  <Box mt={[8, null, 0]}>
    <Heading>{title}</Heading>
    {Array.isArray(details) ? (
      details.map((text) => (
        <Text mt={8} fontSize="md" lineHeight="2">
          {text}
        </Text>
      ))
    ) : (
      <Text mt={8} fontSize="md" lineHeight="2">
        {details}
      </Text>
    )}
  </Box>
)

export const ImageSideBox: React.FC<ImageSideBoxProps> = ({
  image,
  imageAlt,
}) => (
  <Box>
    <Image size="400px" src={image} alt={imageAlt} />
  </Box>
)

export const FlippedOnMobile: React.FC<Props> = (props) => (
  <>
    <SideBySideSection
      sectionPadding={props.sectionPadding}
      left={props.left}
      right={props.right}
      boxProps={{
        ...(props.boxProps || {}),
        display: ['none', null, 'block'],
      }}
    />
    <SideBySideSection
      sectionPadding={props.sectionPadding}
      left={props.right}
      right={props.left}
      boxProps={{
        ...(props.boxProps || {}),
        display: ['block', null, 'none'],
      }}
    />
  </>
)

export default SideBySideSection
