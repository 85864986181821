import * as React from 'react'
import { NavLink, Link } from 'react-router-dom'
import {
  Box,
  Heading,
  Flex,
  FlexProps,
  List,
  ListItem,
  Button,
} from '@chakra-ui/react'
import Logo from './Logo'
import Hamburger from './Hamburger'
import { zIndexes } from 'view/constants'

export interface LinkConfig {
  label: string
  href: string
}

interface Props {
  title: React.ReactNode
  titleLinkHref: string
  links?: LinkConfig[]
  registerLink?: string
  fixedHeader?: boolean
  showRegister?: boolean
  logoutLink?: string
  showLogout?: boolean
}

const MenuItems = (props: React.PropsWithChildren<{}>) => (
  <Box>
    <List display={{ base: 'block', lg: 'flex' }} alignItems="center">
      {props.children}
    </List>
  </Box>
)

const NavBar = (props: FlexProps & Props) => {
  const [show, setShow] = React.useState(false)
  const handleToggle = () => setShow(!show)

  return (
    <Box
      as="nav"
      bg="white"
      boxShadow="0 0.3125rem 0.3125rem -0.25rem rgba(193,196,196,0.75)"
      zIndex={zIndexes.nav}
      position={props.fixedHeader ? 'fixed' : undefined}
      mb="2rem"
      top={0}
      width="full"
    >
      <Flex
        maxWidth="93.75rem"
        margin="0 auto"
        align="center"
        justify="space-between"
        wrap="wrap"
        paddingY={{ base: 4, md: 3 }}
        paddingX={{ base: 4, md: 4 }}
        {...props}
      >
        <Box display={{ base: 'block', lg: 'none' }} onClick={handleToggle}>
          <Hamburger color="brand" open={show} />
        </Box>
        <Flex
          align="center"
          marginX={{ base: 'auto', lg: 0 }}
          marginRight={{ lg: 5 }}
        >
          <Heading as="h1" margin={0} padding={0}>
            <Link to={props.titleLinkHref} color="brand">
              <Box
                width={{ base: '6.875rem', lg: '14.375rem' }}
                marginTop={{ base: 0, lg: 1 }}
              >
                <Logo />
              </Box>
              <Box as="span" className="sr-only">
                {props.title}
              </Box>
            </Link>
          </Heading>
        </Flex>
        <Box
          display={{ base: show ? 'block' : 'none', lg: 'flex' }}
          width={{ base: 'full', lg: 'auto' }}
          alignItems="center"
          flexGrow={1}
        >
          {props.links && (
            <MenuItems>
              {props.links.map((link, i) => (
                <ListItem
                  key={`feature-${i}`}
                  marginRight={{ base: 0, lg: '1rem' }}
                  marginTop={{ base: '1rem', lg: 0 }}
                >
                  <NavLink to={link.href} activeClassName="active">
                    {link.label}
                  </NavLink>
                </ListItem>
              ))}
            </MenuItems>
          )}
        </Box>
        {props.showRegister && props.registerLink ? (
          <Box
            display={{ base: show ? 'block' : 'none', lg: 'block' }}
            marginTop={{ base: 4, lg: 0 }}
          >
            <Link to={props.registerLink}>
              <Button
                size="md"
                variant="solid"
                colorScheme="purple"
                fontWeight="normal"
              >
                Log In
              </Button>
            </Link>
          </Box>
        ) : null}
        {props.showLogout && props.logoutLink ? (
          <Box
            display={{ base: show ? 'block' : 'none', lg: 'block' }}
            marginTop={{ base: 4, lg: 0 }}
          >
            <Link to={props.logoutLink}>
              <Button
                size="md"
                variant="link"
                colorScheme="purple"
                fontWeight="normal"
              >
                Log Out
              </Button>
            </Link>
          </Box>
        ) : null}
      </Flex>
    </Box>
  )
}

export default NavBar
