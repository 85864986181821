import * as React from 'react'
import { Box, Flex, Progress, Skeleton, Text } from '@chakra-ui/react'
import ErrorMessage from 'view/components/ErrorMessage'

interface ChartPageContentsProps {
  isError: boolean
  isLoading: boolean
  isRefreshing: boolean
  lastSyncedString?: string
}
const ChartPageContents = (
  props: React.PropsWithChildren<ChartPageContentsProps>
) => {
  return (
    <Box>
      {props.isError ? (
        <Flex
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Box maxWidth="600px" textAlign="center">
            <ErrorMessage
              title="Failed to load"
              subtitle="Something went wrong on our end"
            />
            <Text textAlign="center" color="red">
              Oops! We did something wrong and failed to generate your charts.
              Please try again and let us know if you are stuck.
            </Text>
          </Box>
        </Flex>
      ) : null}

      <Skeleton isLoaded={!props.isLoading}>
        {props.isRefreshing ? (
          // TODO: tooltip with explanation and last updated time
          <Box mb={4}>
            <Progress hasStripe isAnimated value={100} height="4px" />
          </Box>
        ) : null}

        <Box mb={20}>{props.children ? props.children : null}</Box>

        <Box mt={10}>
          <Text fontSize="xs" textAlign="center">
            {props.lastSyncedString
              ? `Data last updated at ${props.lastSyncedString}`
              : ''}
            {props.isRefreshing ? ' (stale - update in progress)' : ''}
          </Text>
        </Box>
      </Skeleton>
    </Box>
  )
}
export default ChartPageContents
