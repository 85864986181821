import * as React from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'

export interface Props {
  icon: React.ReactNode
  title: string
  details: string
}

const FeatureBox: React.FC<Props> = ({ icon, title, details }) => (
  <Box bg="white" padding={8} borderRadius={5} border="1px solid #d9e4e6">
    {icon ? icon : null}
    <Box>
      <Heading my={4} size="md" textAlign="left" fontSize="lg">
        {title}
      </Heading>
      <Text my={4} fontSize="sm" textAlign="left">
        {details}
      </Text>
    </Box>
  </Box>
)

export default FeatureBox
