import { DatumValue } from '@nivo/core'
import dayjs from 'dayjs'
import { DEFAULT_TIME_FORMAT } from 'view/constants'

export const hoursAndDaysFormatter = (
  days: DatumValue,
  stringWhenZero?: string
) => {
  if (days === 0) {
    return stringWhenZero || 'N/A'
  }

  const safeDays = Math.floor(Number(days))

  if (safeDays >= 1) {
    return englishPlural(safeDays, 'day', 'days')
  }
  const hours = Math.floor(safeDays / 24)
  if (hours >= 1) {
    return englishPlural(hours, 'hour', 'hours')
  }

  return `<1 hour`
}

export const englishPlural = (
  count: number,
  singular: string,
  plural: string
) => `${count} ${count === 1 ? singular : plural}`

export const formatTime = (timestamp: string) =>
  dayjs(timestamp).format(DEFAULT_TIME_FORMAT)
