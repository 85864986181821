import { useQuery } from 'react-query'
import Api from 'api'
import {
  ApiResponse,
  GetTurnaroundPayload,
  GetReviewsPayload,
  GetGeneralPayload,
} from 'api/github'
import { GithubInstallation, GithubRepository, GithubTeam } from './types'
import { Calculator } from 'api/types'

export interface GetRepositoriesResponse {
  items: GithubRepository[]
}

const getRepositoriesRequest = async (
  installationId: string
): Promise<GetRepositoriesResponse> => {
  // TODO: should we validate api responses?
  return Api.github.getRepositories(installationId).then((result) => ({
    items: result.items.map((repo) => ({
      name: repo.name,
      ownerName: repo.owner_name,
      url: repo.url,
    })),
  }))
}

export function useRepositories(installationId: string) {
  return useQuery(
    ['repos', installationId],
    (_key: string, installId: string) => {
      return getRepositoriesRequest(installId)
    }
  )
}

export interface GetInstallationsResponse {
  items: GithubInstallation[]
}

const getInstallationsRequest = async (): Promise<GetInstallationsResponse> => {
  return Api.github.getInstallations().then((result) => ({
    items: result.items.map((install) => ({
      installationId: install.installation_id,
      accountLogin: install.account_login,
      accountType: install.account_type,
      accountAvatarUrl: install.account_avatar_url,
      accountUrl: install.account_url,
      accountId: install.account_id,
    })),
  }))
}

export function useInstallations(opts?: any) {
  return useQuery('installations/all', getInstallationsRequest, opts)
}

export interface GetTeamsResponse {
  items: GithubTeam[]
}

const getTeamsRequest = async (
  ownerName?: string,
  repositoryName?: string
): Promise<GetTeamsResponse> => {
  if (!ownerName || !repositoryName) {
    return { items: [] }
  }

  // TODO: should we validate api responses?
  return Api.github.getTeams(ownerName, repositoryName).then((result) => ({
    items: result.items.map((team) => ({
      name: team.name,
    })),
  }))
}

export function useTeams(
  ownerName?: string,
  repositoryName?: string,
  opts?: any
) {
  return useQuery(
    ['teams', ownerName, repositoryName],
    (_key: string, ownerName?: string, repositoryName?: string) => {
      return getTeamsRequest(ownerName, repositoryName)
    },
    opts
  )
}

const getTurnaroundRequest = async (
  ownerName?: string,
  repositoryName?: string
): Promise<ApiResponse<GetTurnaroundPayload>> => {
  if (!ownerName || !repositoryName) {
    throw new Error(
      'invalid request - ownerName and repositoryName are required fields'
    )
  }

  // TODO: should we validate api responses?
  return Api.github.getTurnaround(ownerName, repositoryName)
}

export function useTurnaround(
  ownerName?: string,
  repositoryName?: string,
  opts?: any
) {
  return useQuery(
    ['turnaround', ownerName, repositoryName],
    (_key: string, ownerName?: string, repositoryName?: string) => {
      return getTurnaroundRequest(ownerName, repositoryName)
    },
    opts
  )
}

const getReviewsRequest = async (
  ownerName?: string,
  repositoryName?: string
): Promise<ApiResponse<GetReviewsPayload>> => {
  if (!ownerName || !repositoryName) {
    throw new Error(
      'invalid request - ownerName and repositoryName are required fields'
    )
  }

  // TODO: should we validate api responses?
  return Api.github.getReviews(ownerName, repositoryName)
}

export function useReviews(
  ownerName?: string,
  repositoryName?: string,
  opts?: any
) {
  return useQuery(
    ['reviews', ownerName, repositoryName],
    (_key: string, ownerName?: string, repositoryName?: string) => {
      return getReviewsRequest(ownerName, repositoryName)
    },
    opts
  )
}

const getGeneralRequest = async (
  ownerName?: string,
  repositoryName?: string,
  calculators?: Calculator[]
): Promise<ApiResponse<GetGeneralPayload>> => {
  if (!ownerName || !repositoryName) {
    throw new Error(
      'invalid request - ownerName and repositoryName are required fields'
    )
  }

  if (!calculators || calculators.length === 0) {
    throw new Error('invalid request - calculators list is required')
  }

  // TODO: should we validate api responses?
  return Api.github.getGeneral(ownerName, repositoryName, calculators)
}

export function useGeneral(
  ownerName?: string,
  repositoryName?: string,
  calculators?: Calculator[],
  opts?: any
) {
  return useQuery(
    ['general', ownerName, repositoryName, calculators],
    (
      _key: string,
      ownerName?: string,
      repositoryName?: string,
      calculators?: Calculator[]
    ) => {
      return getGeneralRequest(ownerName, repositoryName, calculators)
    },
    opts
  )
}
