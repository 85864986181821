import * as React from 'react'
import { Grid } from '@chakra-ui/react'

const Page = (props: React.PropsWithChildren<{}>) => (
  <Grid
    maxWidth="93.75rem"
    margin={['0 auto', '2rem auto']}
    // minHeight="100vh"
    gap={6}
    templateColumns="repeat(auto-fit, minmax(350px, 1fr))"
    // paddingTop="5rem"
    // paddingBottom={{ base: '20.5rem', lg: '13rem' }}
  >
    {props.children}
  </Grid>
)

export default Page
