import { combineReducers } from '@reduxjs/toolkit'

import githubReducer from './auth/githubSlice'

const rootReducer = combineReducers({
  authGithub: githubReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
