import { get, getAuthToken, setAuthToken } from './utils'
import { githubLogin } from './auth'
import githubApi from './github'

const Api = {
  isAuthenticated: (): boolean => getAuthToken() !== null,
  login: {
    github: githubLogin,
  },
  github: githubApi,
  me: async () => await get('me'),
  logout: async () => {
    setAuthToken(null)
    return true
  },
}

export default Api
