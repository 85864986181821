import * as React from 'react'
import { ReviewsByReviewer } from 'api/github'
import { STATUS_COLORS, STATUS_NAMES } from 'view/constants'
import StackedBarChart, { BarChartData } from 'view/components/StackedBarChart'
import ChartPageCard, { WidgetSize } from 'view/components/ChartPageCard'

interface Props {
  size?: WidgetSize
  reviewers?: ReviewsByReviewer
}

const getContents = (reviewers: Exclude<Props['reviewers'], undefined>) => {
  let anyReviewsCompleted = false
  let reviewerMrStatusData: BarChartData[] = []

  Object.keys(reviewers).forEach((reviewerUsername) => {
    const reviewerData = reviewers[reviewerUsername]
    if (reviewerData.reviewed !== 0) {
      anyReviewsCompleted = true
    }

    reviewerMrStatusData.push({
      key: reviewerUsername,
      Closed: reviewerData.closed,
      Open: reviewerData.open,
      Waiting: reviewerData.waiting,
      'In Review': reviewerData.inreview,
      Approved: reviewerData.approved,
      Merged: reviewerData.merged,
    })
  })

  return anyReviewsCompleted ? (
    <StackedBarChart
      data={reviewerMrStatusData}
      yAxisLabel="Reviewer"
      xAxisLabel="PR Count"
      keys={STATUS_NAMES}
      colorMap={{
        Closed: STATUS_COLORS.closed,
        Open: STATUS_COLORS.open,
        Waiting: STATUS_COLORS.waiting,
        'In Review': STATUS_COLORS.inreview,
        Approved: STATUS_COLORS.approved,
        Merged: STATUS_COLORS.merged,
      }}
    />
  ) : null
}

const ReviewsCompleted = (props: Props) => {
  return (
    <ChartPageCard
      size={props.size || 'l'}
      title="PR Status by Reviewer"
      emptyTitle="No Reviews Completed!"
    >
      {props.reviewers ? getContents(props.reviewers) : null}
    </ChartPageCard>
  )
}

export default ReviewsCompleted
