import * as React from 'react'
import { Link } from 'react-router-dom'
import { Box, SimpleGrid, Text } from '@chakra-ui/react'
import Logo from './Logo'

export interface LinkConfig {
  label: string
  href: string
}

interface Props {
  links?: LinkConfig[]
}

const Footer = (props: Props) => (
  <Box
    bg="brand"
    // position="absolute"
    bottom="0"
    width="100%"
    p={[8, null, 16]}
    pb={4}
  >
    <Link to="/">
      <Box width={['8rem', '12rem']} marginBottom={4}>
        <Logo color1="#c7c5f0" color2="#c7c5f0" />
      </Box>
    </Link>
    <SimpleGrid columns={2} spacing={8}>
      <Text color="white" fontSize="xs">
        Made with{' '}
        <Text as="span" color="red.400">
          ❤
        </Text>{' '}
        by team leads that care about measuring the things that matter and
        powering up their teams with tools that give them back their time.
      </Text>
      <Text color="white" fontSize="xs">
        We're on a mission to increase the effectiveness of software developers
        and their teams. If you have a suggestion, request, or comment we would
        love to hear from you at gofast@mergesquad.com
      </Text>
    </SimpleGrid>
    <Text textAlign="center" mt={8} mb={0} as="p" color="purple.200">
      Copyright &copy; 2020 MergeSquad
    </Text>
  </Box>
)

export default Footer
