import * as React from 'react'
import {
  Alert,
  AlertProps,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Heading,
  // Icon,
  Link as RawLink,
} from '@chakra-ui/react'
import { useLocation } from 'react-router'
import { FaGithub } from 'react-icons/fa'
import Api from 'api'
import { prepareForLogin } from 'store/auth/githubSlice'
import Page from './Page'

function useQueryParams() {
  return new URLSearchParams(useLocation().search)
}

const LoginHeader = () => (
  <Heading as="h2" size="md" marginY={4}>
    Log in to MergeSquad
  </Heading>
)

const LoginForm = () => {
  const queryParams = useQueryParams()
  const returnLocation = queryParams.get('return_location')
  const githubLoginUrl = prepareForLogin(returnLocation)

  return (
    <Box marginY={4}>
      <form>
        <RawLink
          href={githubLoginUrl.href}
          py={2}
          px={4}
          rounded="md"
          color="white"
          bg="github.100"
          display="block"
          marginTop={4}
          _hover={{
            textDecoration: 'none',
            backgroundColor: 'black',
          }}
        >
          <Box
            as={FaGithub}
            color="white"
            marginRight={2}
            display="inline-block"
          />{' '}
          Log In with GitHub
        </RawLink>
      </form>
    </Box>
  )
}

// const SignUpForm = () => (
//   <Box marginY={4}>
//     <RawLink
//       href="mailto:beta@mergesquad.com"
//       py={2}
//       px={4}
//       rounded="md"
//       color="white"
//       bg="brand"
//       display="block"
//       _hover={{
//         textDecoration: 'none',
//         backgroundColor: 'purple.600',
//       }}
//     >
//       <Icon name="email" marginRight={2} />
//       Request Early Access
//     </RawLink>
//   </Box>
// )

interface NoticeProps {
  title: string
  details?: string
  status?: AlertProps['status']
}

const Notice = (props: NoticeProps) => (
  <Alert status={props.status || 'error'}>
    <AlertIcon />
    <Box>
      <AlertTitle mr={2}>{props.title}</AlertTitle>
      {props.details ? (
        <AlertDescription display="block">{props.details}</AlertDescription>
      ) : null}
    </Box>
  </Alert>
)

interface Props {
  loggedOut?: boolean
}

const LoginPage = (props: Props) => {
  const queryParams = useQueryParams()
  const authExpired = queryParams.get('auth_expired')

  if (props.loggedOut) {
    Api.logout()
  }

  return (
    <Page>
      <Flex width="full" height="100%" align="center" justifyContent="center">
        <Box
          borderWidth={1}
          paddingX={4}
          width="full"
          maxWidth="22rem"
          borderRadius={4}
          textAlign="center"
          boxShadow="lg"
          bg="white"
        >
          <Box p={2}>
            {authExpired ? (
              <Notice
                title="Your session has expired"
                details="Please log back in to continue."
                status="warning"
              />
            ) : null}
            {props.loggedOut ? (
              <Notice title="You have been logged out" status="info" />
            ) : null}

            <LoginHeader />
            <LoginForm />
          </Box>
        </Box>
      </Flex>
    </Page>
  )
}

export default LoginPage
