import * as React from 'react'
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import Page from 'view/Page'

const UnsupportedLoginPage = () => (
  <Page>
    <Box>
      <Box
        backgroundColor="white"
        borderRadius="lg"
        shadow="sm"
        px={3}
        py={5}
        m={12}
      >
        <Flex
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Heading
            size="md"
            as="h2"
            lineHeight="shorter"
            fontWeight="bold"
            fontFamily="heading"
            textAlign="center"
          >
            Unsupported Platform
          </Heading>
        </Flex>
        <Stack shouldWrapChildren spacing={4} ml={4} mt={4}>
          <Stack shouldWrapChildren spacing={2}>
            <Box textAlign="center">
              <Text fontSize="md" color="gray.600">
                Whoops! Not sure how we got here but we don't support this
                platform (yet?).
              </Text>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Box>
  </Page>
)

export default UnsupportedLoginPage
