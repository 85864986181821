import { theme } from '@chakra-ui/react'

export default {
  ...theme,
  colors: {
    ...theme.colors,
    black: '#081413',
    white: '#FFFFFF',
    primary: '#5849BF',
    text: '#081413',
    secondary: '#ABA4DF',
    blue: {
      50: '#d9f9ff',
      100: '#abe9ff',
      200: '#7cd9ff',
      300: '#4bc8fe',
      400: '#1cb9fc',
      500: '#039fe3',
      600: '#007cb1',
      700: '#005880',
      800: '#003650',
      900: '#00141f',
    },
    green: {
      50: '#defdf8',
      100: '#bbf2e9',
      200: '#95e7dc',
      300: '#6eddd2',
      400: '#49d4bc',
      500: '#30ba9a',
      600: '#22916f',
      700: '#136848',
      800: '#023f25',
      900: '#00170a',
    },
    purple: {
      50: '#ecebff',
      100: '#c7c5f0',
      200: '#a4a1e0',
      300: '#837bd2',
      400: '#6355c3',
      500: '#433caa',
      600: '#2e2f85',
      700: '#212560',
      800: '#131a3c',
      900: '#060b1a',
    },
    red: {
      50: '#ffe2e7',
      100: '#ffb2bc',
      200: '#ff818e',
      300: '#fd4f5b',
      400: '#fb1e25',
      500: '#e10404',
      600: '#b0000e',
      700: '#7e0011',
      800: '#4e0010',
      900: '#200009',
    },
    yellow: {
      50: '#fff6dd',
      100: '#fae9b4',
      200: '#f5df88',
      300: '#f1d85a',
      400: '#edc02e',
      500: '#d49b16',
      600: '#a56e0e',
      700: '#764607',
      800: '#472401',
      900: '#1a0a00',
    },
    brand: '#5849BF',
    github: {
      50: '#24292e',
      100: '#24292e',
      200: '#24292e',
      300: '#24292e',
      400: '#24292e',
      500: '#24292e',
      600: '#333',
      700: '#444',
      800: '#555',
      900: '#666',
    },
  },
  fonts: {
    body: 'Fira Sans, sans-serif',
    heading: 'Fira Sans, sans-serif',
    mono: 'monospace',
  },
  fontSizes: {
    ...theme.fontSizes,
    xs: '14px',
    sm: '16px',
    md: '18px',
    lg: '22px',
    xl: '28px',
    '2xl': '35px',
    '3xl': '43px',
    '4xl': '54px',
  },
  fontWeights: {
    ...theme.fontWeights,
    normal: 400,
    medium: 500,
    bold: 500,
  },
  lineHeights: {
    ...theme.lineHeights,
    base: '1.65',
  },
}
