import * as React from 'react'
import {
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

export type DropdownItem = {
  value: string
  label: string
}

export interface DropdownProps {
  label: string
  items?: DropdownItem[]
  onChange: (value: string) => void
  value: string
  emptyString?: string
  isLoading: boolean
  loadingString?: string
  hasError: boolean
  errorString?: string
  errorValue?: string
  maxContentLength?: number
}

const Dropdown = (props: DropdownProps) => {
  const groupSize = useBreakpointValue({ base: 'xs', sm: 'sm', md: 'md' })
  const groupProps = { size: groupSize }
  const addonProps = { mt: ['2px', 0] }

  if (props.isLoading) {
    return (
      <InputGroup {...groupProps}>
        <InputLeftAddon {...addonProps}>{props.label}</InputLeftAddon>
        <Input
          isReadOnly={true}
          isDisabled={true}
          value={`${props.loadingString || 'Loading...'}`}
        />
      </InputGroup>
    )
  }

  if (props.hasError) {
    return (
      <InputGroup {...groupProps}>
        <InputLeftAddon {...addonProps}>{props.label}</InputLeftAddon>
        <Select isDisabled={true}>
          <option value={props.errorValue}>{`${
            props.errorString || 'Failed to Load'
          }`}</option>
        </Select>
      </InputGroup>
    )
  }

  if (props.items === undefined || props.items.length === 0) {
    return (
      <InputGroup {...groupProps}>
        <InputLeftAddon {...addonProps}>{props.label}</InputLeftAddon>
        <Select isDisabled={true}>
          <option value="">{props.emptyString || 'No Results'}</option>
        </Select>
      </InputGroup>
    )
  }

  return (
    <InputGroup {...groupProps}>
      <InputLeftAddon {...addonProps}>{props.label}</InputLeftAddon>
      <Select
        icon={<ChevronDownIcon />}
        onChange={(e) => props.onChange(`${e.target.value}`)}
        borderLeftRadius={0}
        isTruncated={true}
      >
        {props.items.map((item) =>
          item ? (
            <option value={item.value} selected={item.value === props.value}>
              {item.label}
            </option>
          ) : null
        )}
      </Select>
    </InputGroup>
  )
}

export default Dropdown
