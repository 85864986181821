import * as React from 'react'
import { PRDetails } from 'api/github'
import DivergingBarChart, {
  BarChartData,
} from 'view/components/DivergingBarChart'
import ChartPageCard, { WidgetSize } from 'view/components/ChartPageCard'

interface Props {
  size?: WidgetSize
  prDetails?: PRDetails
}

const getContents = (prDetails: PRDetails) => {
  const authors: { [k: string]: { additions: number; deletions: number } } = {}
  prDetails.forEach((pr) => {
    const authorName = pr.author.name
    if (!authors[authorName]) {
      authors[authorName] = { additions: 0, deletions: 0 }
    }
    authors[authorName].additions += pr.additions_count
    authors[authorName].deletions += pr.deletions_count
  })

  const prChanges: BarChartData[] = prDetails
    .map((pr) => {
      return {
        key: pr.display_name,
        additions: pr.additions_count,
        deletions: -1 * pr.deletions_count,
      }
    })
    .reverse()

  return prChanges.length !== 0 ? (
    <DivergingBarChart
      data={prChanges}
      yAxisLabel="Code Changes"
      xAxisLabel="PRs"
      keys={['additions', 'deletions']}
      colorMap={{
        additions: '#4CF52F',
        deletions: '#FF0D0D',
      }}
      hideLabels={prChanges.length > 10}
    />
  ) : null
}

const CodeChangeByPR = (props: Props) => {
  const prs = props.prDetails || []

  return (
    <ChartPageCard
      size={props.size || 'l'}
      title={`Net Code Change by PR`}
      emptyTitle={`No PRs!`}
    >
      {prs ? getContents(prs) : null}
    </ChartPageCard>
  )
}

export default CodeChangeByPR
