import * as React from 'react'
import { ReviewsByReviewer } from 'api/github'
import PieChart, { PieChartData } from 'view/components/PieChart'
import ChartPageCard, { WidgetSize } from 'view/components/ChartPageCard'

interface Props {
  size?: WidgetSize
  reviewers?: ReviewsByReviewer
}

const getContents = (reviewers: Exclude<Props['reviewers'], undefined>) => {
  let anyReviewsCompleted = false
  let reviewsCompletedData: PieChartData[] = []

  Object.keys(reviewers).forEach((reviewerUsername) => {
    const reviewerData = reviewers[reviewerUsername]
    reviewsCompletedData.push({
      id: reviewerUsername,
      label: reviewerUsername,
      value: reviewerData.reviewed,
    })

    if (reviewerData.reviewed !== 0) {
      anyReviewsCompleted = true
    }
  })

  return anyReviewsCompleted ? <PieChart data={reviewsCompletedData} /> : null
}

const ReviewsCompleted = (props: Props) => {
  return (
    <ChartPageCard
      size={props.size || 's'}
      title="Reviews Completed"
      emptyTitle="No Reviews Completed!"
    >
      {props.reviewers ? getContents(props.reviewers) : null}
    </ChartPageCard>
  )
}

export default ReviewsCompleted
