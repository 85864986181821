import * as React from 'react'
import { PRTurnaroundBuckets } from 'api/github'
import ChartPageCard, { WidgetSize } from 'view/components/ChartPageCard'
import StackedBarChart, { BarChartData } from 'view/components/StackedBarChart'

interface Props {
  size?: WidgetSize
  turnaroundByWeek?: PRTurnaroundBuckets
  showIfEmpty?: boolean
}

const getContents = (
  turnaroundByWeek: Exclude<Props['turnaroundByWeek'], undefined>
) => {
  let turnaroundBuckets: BarChartData[] = []

  Object.keys(turnaroundByWeek).forEach((week) => {
    const bucket = {
      key: week,
      ...turnaroundByWeek[week],
    }
    turnaroundBuckets.push(bucket)
  })

  return turnaroundBuckets.length !== 0 ? (
    <StackedBarChart
      data={turnaroundBuckets}
      keys={[
        '<3 hrs',
        '<5 hrs',
        '<8 hrs',
        '<24 hrs',
        '<48 hrs',
        '<72 hrs',
        '72+ hrs',
      ]}
      yAxisLabel="PRs"
      xAxisLabel="Weeks"
      colorMap={{
        '<3 hrs': '#4CF52F',
        '<5 hrs': '#69B34C',
        '<8 hrs': '#ACB334',
        '<24 hrs': '#FAB733',
        '<48 hrs': '#FF8E15',
        '<72 hrs': '#FF4E11',
        '72+ hrs': '#FF0D0D',
      }}
    />
  ) : null
}

const PRStatusByReviewer = (props: Props) => {
  let hasTurnaround = false
  if (props.turnaroundByWeek !== undefined) {
    const weeks = Object.keys(props.turnaroundByWeek)
    for (let i = 0; i < weeks.length && !hasTurnaround; i++) {
      const bucketCounts = props.turnaroundByWeek[weeks[i]]
      const buckets = Object.keys(bucketCounts)
      for (let j = 0; j < buckets.length && !hasTurnaround; j++) {
        if (bucketCounts[buckets[j]] > 0) {
          hasTurnaround = true
        }
      }
    }
  }

  const show = props.turnaroundByWeek && (hasTurnaround || props.showIfEmpty)

  return show ? (
    <ChartPageCard
      size={props.size || 'l'}
      title="First Review Turnaround by Week"
      emptyTitle="No Reviews During Time Frame!"
    >
      {props.turnaroundByWeek && hasTurnaround
        ? getContents(props.turnaroundByWeek)
        : null}
    </ChartPageCard>
  ) : null
}

export default PRStatusByReviewer
