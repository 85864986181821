import * as React from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'

interface Props {
  title: React.ReactNode
  subtitle?: React.ReactNode
}

const ErrorMessage = (props: Props) => (
  <Alert status="error">
    <AlertIcon />
    <AlertTitle mr={2}>{props.title}</AlertTitle>
    {props.subtitle ? (
      <AlertDescription>{props.subtitle}</AlertDescription>
    ) : null}
  </Alert>
)

export default ErrorMessage
