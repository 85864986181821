import { post, setAuthToken } from './utils'

export async function githubLogin(code: string) {
  return await post('rest-auth/github/', { code }, false)
    .then((response) => {
      if (response.key) {
        setAuthToken(response.key)
        return
      }
      throw new Error('failed to authenticate')
    })
    .catch(() => {
      throw new Error('failed to authenticate')
    })
}
