import * as React from 'react'
import { Box, Text } from '@chakra-ui/react'

export type WidgetSize = 's' | 'm' | 'l'

interface Props {
  size: WidgetSize
  title: string
  emptyTitle: string
}

export const SMALL_MAX_HEIGHT = 250
export const BIG_MAX_HEIGHT = 300

const empty = (text: string) => (
  <Box minWidth={300} h="100%">
    <Text align="center" fontSize="large" mt="85px">
      {text}
    </Text>
  </Box>
)

const SmallBox = (props: React.PropsWithChildren<{}>) => (
  <Box minWidth={300} h={SMALL_MAX_HEIGHT}>
    {props.children}
  </Box>
)

const MidBox = (props: React.PropsWithChildren<{}>) => (
  <Box minWidth={600} h={SMALL_MAX_HEIGHT}>
    {props.children}
  </Box>
)

const BigBox = (props: React.PropsWithChildren<{}>) => (
  <Box w="100%" h={BIG_MAX_HEIGHT} pt={10}>
    {props.children}
  </Box>
)

const ChartPageCard = (props: React.PropsWithChildren<Props>) => {
  const contents = (
    <>
      <Text align="center">{props.title}</Text>
      {props.children || empty(props.emptyTitle)}
    </>
  )

  if (props.size === 's') {
    return <SmallBox>{contents}</SmallBox>
  } else if (props.size === 'm') {
    return <MidBox>{contents}</MidBox>
  } else {
    return <BigBox>{contents}</BigBox>
  }
}

export default ChartPageCard
