import * as React from 'react'
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import store from '../../store/store'
import {
  convertCodeToAuth,
  selectIsValid,
  selectIsInFlight,
  selectCodeConversionSuccess,
} from '../../store/auth/githubSlice'
import Page from 'view/Page'
import ErrorMessage from 'view/components/ErrorMessage'

function useQueryParams() {
  return new URLSearchParams(useLocation().search)
}

/**
 * this page is the result of github's post-auth redirect
 * queryParams include a 'code' we need to exchange for auth with our backend
 * and a 'state' to check against the state we originally used when we
 * requested auth
 */
const GithubLoginPage = () => {
  const history = useHistory()

  // get code and state from url
  const query = useQueryParams()
  const code = query.get('code')
  const secretState = query.get('state')

  // TODO: these aren't quite right, which causes the error to flicker when
  // visiting this page
  const isValid = useSelector(selectIsValid)
  const isInFlight = useSelector(selectIsInFlight)
  const conversionSuccess = useSelector(selectCodeConversionSuccess)

  React.useEffect(() => {
    // turn code from url into authentication key
    store.dispatch(convertCodeToAuth(code, secretState))
  })

  React.useEffect(() => {
    if (conversionSuccess) {
      // TODO: should this component be in charge of knowing what happens next?

      // TODO: pre-request installations?

      let redirectLocation = '/platforms/github'
      try {
        const parsedState = secretState ? JSON.parse(secretState) : {}
        if (parsedState.return_location) {
          redirectLocation = parsedState.return_location
        }
      } catch (e) {}

      history.push(redirectLocation)
    }
  }, [conversionSuccess, history, secretState])

  if (isInFlight) {
    return <LoadingContents />
  } else if (conversionSuccess) {
    return <SuccessContents />
  } else if (isValid === false) {
    return <ErrorContents />
  }
  return <ErrorContents />
}

const LoadingContents = () => (
  <Contents
    header="GitHub Login Successful"
    subheader="Retrieving Your Account Info..."
  >
    <Text fontSize="md" color="gray.600">
      Thanks for authenticating your GitHub account! Connecting...
    </Text>
  </Contents>
)

const SuccessContents = () => (
  <Contents header="GitHub Login Successful" subheader="Authenticated!">
    <Text fontSize="md" color="gray.600">
      Thanks for authenticating your GitHub account! Give us a moment while we
      grab your repository data...
    </Text>
  </Contents>
)

const ErrorContents = () => (
  <Contents header="GitHub Login Failed" subheader="Oops, something went wrong">
    <ErrorMessage
      title="Failed to Log In"
      subtitle="We failed to access your GitHub account"
    />
    <Text fontSize="md" color="gray.600">
      Something went sideways while we were trying to log you in. Please try
      again and let us know if you're stuck.
    </Text>
  </Contents>
)

interface ContentProps {
  header: string
  subheader?: string
}

const Contents = (props: React.PropsWithChildren<ContentProps>) => (
  <Page>
    <Box>
      <Box
        backgroundColor="white"
        borderRadius="lg"
        shadow="sm"
        px={3}
        py={5}
        m={12}
      >
        <Flex
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Heading
            size="md"
            as="h2"
            lineHeight="shorter"
            fontWeight="bold"
            fontFamily="heading"
            textAlign="center"
          >
            {props.header}
          </Heading>
          {props.subheader && <Text>{props.subheader}</Text>}
        </Flex>
        <Stack shouldWrapChildren spacing={4} ml={4} mt={4}>
          <Stack shouldWrapChildren spacing={2}>
            <Box textAlign="center">{props.children}</Box>
          </Stack>
        </Stack>
      </Box>
    </Box>
  </Page>
)

export default GithubLoginPage
